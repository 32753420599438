import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/base/Spinner";
import UserContext from "../context/UserContext";
import Explanations from "../components/ThreatFeeds/Explanations";
import Header from "../components/base/Header";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const { forgotPassword, loading } = useContext(UserContext);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    const userObj = {
      email: email,
    };

    const res = await forgotPassword(userObj);

    if (res.status === 200) {
      navigate("/resetting");
    } else {
      if (res.response.data.detail[0].msg) {
        toast.error(res.response.data.detail[0].msg);
      } else {
        toast.error(res.data.detail);
      }
    }
  };

  return (
    <div className="boxes">
      <div className="box">
        <p className="header">Forgot Your Password?</p>
        <p className="sub_header">
          Enter the email address that you used to register and we will email
          you a link to set a new password.
        </p>
        <form onSubmit={onSubmit} className="form">
          <div className="fieldContainer">
            <label className="label" htmlFor="email">
              Email:
            </label>
            <input
              className="input"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button className="submitBtn" type="submit">
            Send Email
          </button>
        </form>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default Forgot;
