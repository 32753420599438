import Tooltip from "../base/Tooltip"

const Tools = () => {
    return (
        <div className='box'>
            <h2 className='header'>Tools</h2>
            <div className='tool_list'>
                <div className="tool">
                    <a href="https://github.com/Forescout/project-memoria-detector" target="_blank">
                        <Tooltip direction="top" content="The project-memoria-detector tool aims to determine whether a target network device runs a specific embedded TCP/IP stack. Currently, the tool supports fingerprints for 16 embedded TCP/IP stacks (and their variations)">
                            <img src="icons/project_memoria_detector.svg" />
                            <p>Project Memoria Detector</p>
                        </Tooltip>
                    </a>
                </div>
                <div className="tool">
                    <a href="https://github.com/Forescout/namewreck" target="_blank">
                        <Tooltip direction="top" content="This repository contains two artifacts: (1) A set of Joern scripts for detecting issues similar to NAME:WRECK (joern-queries) in the source code using static analysis; and (2) the text of an informational Internet-Draft (rfc) that explains common vulnerabilities behind NAME:WRECK.">
                            <img src="icons/namewreck.svg" />
                            <p>Namewreck</p>
                        </Tooltip>
                    </a>
                </div>
                <div className="tool">
                    <a href="https://github.com/Forescout/mirai_strings_deobfuscator" target="_blank">
                        <Tooltip direction="top" content="This tool provides a simple and easy way to retrieve obfuscated strings from various Mirai variants: you simply need to run the script against a Mirai sample, and you should get de-obfuscated strings as the output.">
                            <img src="icons/Mirai-Strings-Deobfuscator.svg" />
                            <p>Mirai Strings Deobfuscator</p>
                        </Tooltip>
                    </a>
                </div>
                <div className="tool">
                    <a href="https://github.com/Forescout/bgp_boofuzzer" target="_blank">
                        <Tooltip content="The goal of this project was to implement a simple black-box fuzzer for various BGP protocol implementations. For the moment, the focus of the fuzzer is malformed packets, but we believe that it can be extended to cover some of the bugs related to the BGP state machine. We chose to build upon BooFuzz." direction="top">
                            <img src="icons/BGP-Fuzzer.svg" />
                            <p>BGP Boofuzzer</p>
                        </Tooltip>
                    </a>
                </div>
            </div>
        </div >
    )
}

export default Tools