const DisclosurePolicy = () => {
    return (
        <div className='box'>
            <h2 className="header">Disclosure Policy</h2>
            <div className="disclosure_policy">
                <img src="pictures/FS-2023-v1-REM.webp" />
                <p>Check out our disclosure policy <a href="https://www.forescout.com/resources/forescout-vulnerability-disclosure-policy/" target="_blank">here</a></p>
            </div>
        </div>
    )
}

export default DisclosurePolicy