import { createContext, useState } from "react";
import axios from "axios";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const userStorage = JSON.parse(sessionStorage.getItem("user"));

  const [user, setUser] = useState(userStorage ? userStorage : null);
  const [loading, setLoading] = useState(false);

  const API_URL = "https://feed.vederelabs.com/feedservice/api/";

  // Create an axios instance with the common configurations
  const api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
  });

  const showPassword = () => {
    const elements = document.getElementsByClassName("passQry");

    for (let i = 0; i < elements.length; i++) {
      const type =
        elements[i].getAttribute("type") === "password" ? "text" : "password";

      elements[i].setAttribute("type", type);
    }
  };

  // Create new user
  //
  // newUser object fields {
  //    name                  string,
  //    last_name             string,
  //    company               string,
  //    email                 string,
  //    password              string,
  //    is_privacy_accepted   boolean,
  //    captcha_token         string,
  // }
  const createUser = async (newUser) => {
    setLoading(true);

    let response;
    try {
      response = await api.post("users/", newUser);
    } catch (error) {
      setLoading(false);
      return error;
    }

    setLoading(false);
    return response;
  };

  // Update Password
  //
  // userObj fields {
  //    name                  string,
  //    last_name             string,
  //    company               string,
  //    email                 string,
  //    FS_CRSF_TOKEN_COOKIE  string,
  //    FS_CRSF_TOKEN_HEADER  string,
  //    current_password      string(optional),
  //    password              string(optional),
  //    api_token_request     boolean(optional),
  // }
  const updateUser = async (userObj) => {
    setLoading(true);

    const request = axios.create({
      withCredentials: true,
      baseURL: API_URL,
    });

    request.defaults.headers.common["X-XSRF-TOKEN"] = findCookie("XSRF-TOKEN");

    let response;
    try {
      response = await request.put("users/me", userObj);
      findCookie("XSRF-TOKEN")
    } catch (error) {
      setLoading(false);
      return error.response;
    }

    if (response.status === 200) {
      sessionStorage.setItem("user", JSON.stringify(response.data));
      setUser(response.data);
    }

    setLoading(false);
    return response;
  };

  // Function to sign out current user
  const signOut = async () => {
    setLoading(true);

    let response;
    try {
      response = await api.post("auth/logout");
    } catch (error) {
      setLoading(false);
    }

    // We remove session storage and user even if the status
    // is not 200
    sessionStorage.removeItem("user");
    setUser(null);

    setLoading(false);
    return response;
  };

  // Sign In User
  //
  // userObj fields {
  //    email     string,
  //    password  string,
  // }
  const signIn = async (userObj) => {
    setLoading(true);

    let response;
    try {
      response = await api.post("auth/login", userObj);
    } catch (error) {
      setLoading(false);
      return error.response;
    }

    if (response.status === 200) {
      const res = getUser();
      return res;
    }
  };

  // Function to return current user's information
  const getUser = async () => {
    let response;
    try {
      response = await api.get("users/me");
    } catch (error) {
      setLoading(false);
      return error.response;
    }

    if (response.status === 200) {
      sessionStorage.setItem("user", JSON.stringify(response.data));
      setUser(response.data);
    }

    setLoading(false);
    return response;
  };

  // Function to initiate password reset
  //
  // userObj fields {
  //    email string
  // }
  const forgotPassword = async (userObj) => {
    let response;

    try {
      response = await api.post("auth/forgot", userObj);
    } catch (error) {
      setLoading(false);
      return error.response;
    }

    setLoading(false);
    return response;
  };

  // Function to reset the password
  //
  // userObj fields {
  //    password  string,
  //    token     string,
  // }
  const resetPassword = async (userObj) => {
    let response;

    try {
      response = await api.post("users/password_reset", userObj);
    } catch (error) {
      setLoading(false);
      return error.response;
    }

    setLoading(false);
    return response;
  };

  // Function to find a certain cookie
  const findCookie = (cookieName) => {
    let name = cookieName + "=";

    console.log(document.cookie);
    let decodedCookie = decodeURIComponent(document.cookie);
    console.log(decodedCookie);

    let cookies = decodedCookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];

      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }

      console.log(c.indexOf(name))
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
  };

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        setUser,
        setLoading,
        showPassword,
        createUser,
        updateUser,
        signOut,
        signIn,
        getUser,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
