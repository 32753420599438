import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "../context/UserContext";
import { getThreatIntel, getLatestFeed, getThreatDashboard, getThreatBriefs } from "../features/data/dataSlice";
import Footer from "../components/base/Footer";
import Error from "../components/base/Error";
import Navbar from "../components/base/Navbar";
import PrivateRoute from "../components/base/routes/PrivateRoute";
import Spinner from "../components/base/Spinner";
import Grid from "./Grid";
import Documentation from "./Documentation";
import RegisterPage from "./RegisterPage";
import SignInPage from "./SignInPage";
import Forgot from "./Forgot";
import Reset from "./Reset";
import Profile from "./Profile";
import UsePolicy from "./UsePolicy";
import Resetting from "./Resetting";
import ThreatBriefs from "./ThreatBriefs";
import ThreatActor from "./ThreatActor";
import Vulnerabilities from "./Vulnerabilities";
import useWindowDimensions from '../hooks/useWindowDimensions/useWindowDimensions'
import SmallNavbar from "../components/base/SmallNavbar";
import Copilot from "../components/MicrosoftCopilot/Copilot";

function App() {
  const [width, setWidth] = useState(useWindowDimensions());

  const dispatch = useDispatch()

  const { threat_intel, threat_dashboard, isSuccess, isLoading } = useSelector(
    (state) => state.data
  )

  useEffect(() => {
    dispatch(getThreatIntel())
    dispatch(getLatestFeed())
    dispatch(getThreatDashboard())
    dispatch(getThreatBriefs())
  }, [])

  if (isSuccess && threat_intel && threat_dashboard) {
    return (
      <UserProvider>
        <Router>
          <div className="App">
            {width['width'] < 1280 ? <SmallNavbar /> : <Navbar />}
            <div>
              <Routes>
                <Route path="/" element={<Grid />} />
                <Route path="/acceptableuse" element={<UsePolicy />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/signin" element={<SignInPage />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/resetting" element={<Resetting />} />
                <Route path="/" element={<PrivateRoute />}>
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/documentation" element={<Documentation />} />
                </Route>
                <Route path="/attacks-dashboard" element={<ThreatBriefs />} />
                <Route path="/threat-actors-dashboard" element={<ThreatActor />} />
                <Route path="vulnerabilities" element={<Vulnerabilities />} />
                <Route path="/copilot" element={<Copilot />} />
                <Route path="/*" element={<Error />} />
              </Routes>
              <Footer
                legal={threat_intel.bottom_disclaimer}
                lastUpdate={threat_intel["Data Last Update"]}
              />
            </div>
            {isLoading && <Spinner />}
          </div>
        </Router>
        <ToastContainer />
      </UserProvider>
    );
  }
}

export default App;
