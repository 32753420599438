import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";
import Explanations from "../components/ThreatFeeds/Explanations";
import SignIn from "../components/ThreatFeeds/SignIn";
import Spinner from "../components/base/Spinner";
import Header from "../components/base/Header";

const FeedService = () => {
  const { loading, user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      typeof user === "object" &&
      !Array.isArray(user) &&
      user !== null
    ) {
      navigate("/profile");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      <section className="eindhoven-background-image">
        <div className="boxes">
          <Explanations />
          <SignIn />
        </div>
      </section>
      {loading && <Spinner />}
    </main>
  );
};

export default FeedService;
