import Explanations from "../components/ThreatFeeds/Explanations";
import Header from "../components/base/Header";

const Resetting = () => {
  return (
    <main>
      <section className="eindhoven-background-image">
        <div className="boxes">
          <Explanations />
          <div className="form-box">
            <p className="header">Resetting Your Password</p>
            <p className="description-text">
              An email has been sent to the email address if that email address
              has been registered. The email will contain instructions on how to
              reset your password.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Resetting;
