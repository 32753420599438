import canada_states from "./canada_states"

const CanadaStateInput = ({ state, onChange }) => {
    return (
        <div className="fieldContainer">
            <select name="state" className="input" value={state} required onChange={onChange}>
                <option value={"Choose State"}>Choose State</option>
                {canada_states.map((province) =>
                    <option value={province.abbreviation}>{province.name}</option>
                )}
            </select>
        </div>
    )
}

export default CanadaStateInput