import { useState, useEffect } from "react";
import GreyCheck from "./GreyCheck";
import GreenCheck from "./GreenCheck";

const PasswordReq = ({ password }) => {
  const [length, setLength] = useState(false);
  const [capitol, setCapitol] = useState(false);
  const [lower, setLower] = useState(false);
  const [number, setNumber] = useState(false);
  const [special, setSpecial] = useState(false);

  useEffect(() => {
    var upperSearch = new RegExp("^(?=.*[A-Z])");
    var lowerSearch = new RegExp("^(?=.*[a-z])");
    var numberSearch = new RegExp("^(?=.*[0-9])");
    var specialSearch = new RegExp("^(?=.*[-+_!@#$%^&*.,?])");

    if (password.length > 7) {
      setLength(true);
    } else {
      setLength(false);
    }

    if (upperSearch.test(password)) {
      setCapitol(true);
    } else {
      setCapitol(false);
    }

    if (lowerSearch.test(password)) {
      setLower(true);
    } else {
      setLower(false);
    }

    if (numberSearch.test(password)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    if (specialSearch.test(password)) {
      setSpecial(true);
    } else {
      setSpecial(false);
    }
  }, [password]);

  return (
    <div className="passReq hidden" id="passReq">
      <p className="passHeader">Set Your Password</p>
      <p className="passTitle">Password must meet minimum requirements:</p>
      <div className="reqContainer">
        {length ? <GreenCheck /> : <GreyCheck />}
        <p className="req">8 character minimum</p>
      </div>
      <div className="reqContainer">
        {capitol ? <GreenCheck /> : <GreyCheck />}
        <p className="req">1 uppercase letter</p>
      </div>
      <div className="reqContainer">
        {lower ? <GreenCheck /> : <GreyCheck />}
        <p className="req">1 lowercase letter</p>
      </div>
      <div className="reqContainer">
        {number ? <GreenCheck /> : <GreyCheck />}
        <p className="req">1 number</p>
      </div>
      <div className="reqContainer">
        {special ? <GreenCheck /> : <GreyCheck />}
        <p className="req">1 special character</p>
      </div>
    </div>
  );
};

export default PasswordReq;
