import { useState } from 'react';

function Info({ description }) {
    const { text, url, url_text } = description;
    const [active, setActive] = useState(false);
    const [iconText, setIconText] = useState('i');

    const toggleTooltip = () => {
        setActive(active => !active);
    }

    return (
        <div className="Info" onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}>
            <div className="icon">{iconText}</div>
            {active && (
                <div className="tooltip">
                    <p>{text}</p>
                    {url && url_text && (
                        <p><a href={url} target="_blank" rel="noreferrer">{url_text}</a></p>
                    )}
                </div>
            )}
        </div>
    )
}

export default Info;