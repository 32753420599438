import Info from "./Info";

function CriticalVulns(props) {
    if (props.data == null) {
        return (
            <div className='SecurityFeed'>
                <p>Null</p>
            </div>
        )
    }

    const { data, title, tooltip } = props.data;

    return (
        <div className="CriticalVulns">
            <Info description={tooltip} />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <section>
                <table>
                    <tbody>
                        {data.map((datum) => (
                            <tr key={datum.cve_id}>
                                <td width="15%">{datum.date}</td>
                                <td width="10%"><span className="score">{datum.cvss_score}</span></td>
                                <td className="title" width="50%"><a target="_blank" rel="noreferrer" href={datum.url}>{datum.summary}</a></td>
                                <td width="15%">{datum.cve_id}</td>
                                <td width="10%"><a target="_blank" rel="noreferrer" href={datum.url}>View</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    )
}

export default CriticalVulns;