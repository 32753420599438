import { toast } from "react-toastify";
import { FaCopy, FaExpandAlt, FaTimes } from "react-icons/fa";

const SingleIndicator = () => {
    const showExample = (show) => {
        document.getElementById(show).classList.remove("hidden");
    };

    const hideExample = (hide) => {
        document.getElementById(hide).classList.add("hidden");
    };

    const copyExample = () => {
        navigator.clipboard.writeText(
            "import requests\r\nurl = 'https://api.feeds.vederelabs.com/feedservice/api/indicators/?value=192.241.199.238'\r\nheaders = {\r\n\t'api-key' : '{your_api_key}'\r\n}\r\nresponse = requests.get(url, headers=headers)\r\nprint(response.text)"
        );
        toast.success("API request has been copied!", {
            position: toast.POSITION.TOP_LEFT,
        });
    };

    return (
        <div className="column">
            <div className="desc-section">
                <h2>Single Indicator</h2>
                <p>
                    This API query call will enable you to look for an indicator (malicious URLs, IPv4 addresses, domain names, or file hashes). The format of this query is a STIX Bundle Object, that contains a single indicator STIX objects, with related Sighting and Attack Pattern objects. The Indicator Object contains the "pattern" field where you can find a single indicator (e.g., [ipv4-addr:value = '107.189.7.197']). A STIX Bundle is a collection of arbitrary STIX Objects grouped in a single container.
                </p>
            </div>
            <div className="example">
                <div className="codeHeader">
                    <p>API Request (Python)</p>
                    <FaCopy className="icon" onClick={() => copyExample()} />
                </div>
                <div className="code">
                    <p className="codeLine">
                        <span className="highlight">import</span> requests
                    </p>
                    <p className="codeLine">
                        url <span className="highlight">= "</span>
                        <span className="quote">
                            https://api.feeds.vederelabs.com/feedservice/api/indicators/?value=192.241.199.238
                        </span>
                        <span className="highlight">"</span>
                    </p>
                    <p className="codeLine">
                        headers <span className="highlight">= </span> &#123;
                    </p>
                    <p className="codeLine tab">
                        <span className="highlight">'api-key' </span>:
                        <span className="highlight"> '&#123;your_api_key&#125;'</span>
                    </p>
                    <p className="codeLine">&#125;</p>
                    <p className="codeLine">
                        response <span className="highlight">=</span> requests.get(url,
                        headers=headers)
                    </p>
                    <p className="codeLine">
                        <span className="secHighlight">print</span>(response.text)
                    </p>
                </div>
            </div>
            <div className="responses">
                <div className="response" onClick={() => showExample("code1")}>
                    <div className="greenCircle"></div>
                    <p>HTTP code 200 - Successful Response</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code1">
                    <FaTimes className="close" onClick={() => hideExample("code1")} />
                    <p>&#123;</p>
                    <p className="tab">"type": "bundle",</p>
                    <p className="tab">
                        "id": "bundle--04469d79-6cbf-4d38-9ce2-1316a1c4fd13",
                    </p>
                    <p className="tab">"objects": [</p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "indicator",</p>
                    <p className="tab3">"spec_version": "2.1",</p>
                    <p className="tab3">
                        "id": "indicator--cd3bb847-d994-408a-8d59-4cbafb067748",
                    </p>
                    <p className="tab3">"created": "2022-12-05T10:39:22.054063Z",</p>
                    <p className="tab3">"modified": "2022-12-05T10:39:22.054063Z",</p>
                    <p className="tab3">"name": "Malicious IPv4 address",</p>
                    <p className="tab3">
                        "description": "Detected malicious IPv4 addresses from the
                        VedereLabs Adversary Engagement Environment.",
                    </p>
                    <p className="tab3">
                        "pattern": "[ipv4-addr:value = '192.241.199.238']",
                    </p>
                    <p className="tab3">"pattern_type": "stix",</p>
                    <p className="tab3">"pattern_version": "2.1",</p>
                    <p className="tab3">"valid_from": "2022-12-04T17:30:46.520497Z",</p>
                    <p className="tab3">"valid_until": "2023-01-03T17:30:46.520497Z",</p>
                    <p className="tab3">"kill_chain_phases": [</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"kill_chain_name": "mitre-attack",</p>
                    <p className="tab5">"phase_name": "credential-access"</p>
                    <p className="tab4">&#125;</p>
                    <p className="tab3">],</p>
                    <p className="tab3">"labels": [</p>
                    <p className="tab4">"malicious-activity"</p>
                    <p className="tab3">],</p>
                    <p className="tab3">"confidence": 85,</p>
                    <p className="tab3">"external_references": [</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"source_name": "Vedere Labs",</p>
                    <p className="tab5">
                        "url": "https://www.forescout.com/research-labs/"
                    </p>
                    <p className="tab4">&#125;</p>
                    <p className="tab3">],</p>
                    <p className="tab3">"x_as_org": "DIGITALOCEAN-ASN",</p>
                    <p className="tab3">"x_asn": 14061,</p>
                    <p className="tab3">"x_city_name": "San Francisco",</p>
                    <p className="tab3">"x_country_code": "US",</p>
                    <p className="tab3">"x_country_name": "United States",</p>
                    <p className="tab3">"x_latitude": 37.7308,</p>
                    <p className="tab3">"x_longtitude": -122.3838,</p>
                    <p className="tab3">"x_postal_code": "94124"</p>
                    <p className="tab2">&#125;,</p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "sighting",</p>
                    <p className="tab3">"spec_version": "2.1",</p>
                    <p className="tab3">
                        "id": "sighting--53d0cbfc-ee45-495d-ba27-8b93b21d1be5",
                    </p>
                    <p className="tab3">"created": "2022-12-05T10:39:22.055519Z",</p>
                    <p className="tab3">"modified": "2022-12-05T10:39:22.055519Z</p>
                    <p className="tab3">"first_seen": "2022-12-04T17:30:46.520497Z",</p>
                    <p className="tab3">"last_seen": "2022-12-04T17:30:46.520497Z",</p>
                    <p className="tab3">"count": 1,</p>
                    <p className="tab3">
                        "sighting_of_ref":
                        "indicator--cd3bb847-d994-408a-8d59-4cbafb067748",
                    </p>
                    <p className="tab2">&#125;,</p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "attack-pattern",</p>
                    <p className="tab3">"spec_version": "2.1",</p>
                    <p className="tab3">
                        "id": "attack-pattern--931016bd-6d78-48c3-9436-46dfe0b3c897",
                    </p>
                    <p className="tab3">"created": "2022-12-05T10:39:22.056112Z",</p>
                    <p className="tab3">"modified": "2022-12-05T10:39:22.056112Z",</p>
                    <p className="tab3">"name": "Brute Force",</p>
                    <p className="tab3">
                        "description": "Adversaries may use brute force techniques to gain
                        access to accounts when passwords are unknown or when password
                        hashes are obtained.Without knowledge of the password for an account
                        or set of accounts, an adversary may systematically guess the
                        password using a repetitive or iterative mechanism. Brute forcing
                        passwords can take place via interaction with a service that will
                        check the validity of those credentials or offline against
                        previously acquired credential data, such as password
                        hashes.\n\nBrute forcing credentials may take place at various
                        points during a breach. For example, adversaries may attempt to
                        brute force access to [Valid
                        Accounts](https://attack.mitre.org/techniques/T1078) within a victim
                        environment leveraging knowledge gathered from other post-compromise
                        behaviors such as [OS Credential
                        Dumping](https://attack.mitre.org/techniques/T1003), [Account
                        Discovery](https://attack.mitre.org/techniques/T1087), or [Password
                        Policy Discovery](https://attack.mitre.org/techniques/T1201).
                        Adversaries may also combine brute forcing activity with behaviors
                        such as [External Remote
                        Services](https://attack.mitre.org/techniques/T1133) as part of
                        Initial Access.",{" "}
                    </p>
                    <p className="tab3">"external_references": [</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"source_name": "mitre-attack",</p>
                    <p className="tab5">
                        "url": "https://attack.mitre.org/techniques/T1110",
                    </p>
                    <p className="tab5">"external_id": "T1110"</p>
                    <p className="tab4">&#125;</p>
                    <p className="tab3">]</p>
                    <p className="tab2">&#125;,</p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "relationship",</p>
                    <p className="tab3">"spec_version": "2.1",</p>
                    <p className="tab3">
                        "id": "relationship--89e13662-6950-4d99-b646-6c1cc659d04c",
                    </p>
                    <p className="tab3">"created": "2022-12-05T10:39:22.056366Z",</p>
                    <p className="tab3">"modified": "2022-12-05T10:39:22.056366Z",</p>
                    <p className="tab3">"relationship_type": "indicates",</p>
                    <p className="tab3">
                        "source_ref": "indicator--cd3bb847-d994-408a-8d59-4cbafb067748",
                    </p>
                    <p className="tab3">
                        "target_ref":
                        "attack-pattern--931016bd-6d78-48c3-9436-46dfe0b3c897",
                    </p>
                    <p className="tab2">&#125;</p>
                    <p className="tab">]</p>
                    <p>&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code2")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 401 - Not authenticated</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code2">
                    <FaTimes className="close" onClick={() => hideExample("code2")} />
                    <p>&#123;"detail": "Not Authenticated"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code3")}>
                    <div className="redCircle"></div>
                    <p>
                        HTTP code 403 - User has not permissions to access the requested
                        resource
                    </p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code3">
                    <FaTimes className="close" onClick={() => hideExample("code3")} />
                    <p>
                        &#123;"detail": "User has not permissions to access the requested
                        resource"&#125;
                    </p>
                </div>

                <div className="response" onClick={() => showExample("code4")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 404 - Not found</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code4">
                    <FaTimes className="close" onClick={() => hideExample("code4")} />
                    <p>&#123;"detail": "No valid indicators"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code5")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 422 - Validation error</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code5">
                    <FaTimes className="close" onClick={() => hideExample("code5")} />
                    <p>&#123;"detail": "Validation error"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code6")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 500 - Error processing the request</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code6">
                    <FaTimes className="close" onClick={() => hideExample("code6")} />
                    <p>&#123;"detail": "Internal error"&#125; </p>
                </div>
            </div>
            <div className="tableOverflow">
                <table className="table">
                    <thead>
                        <tr className="tableRow">
                            <th className="tableHead">Parameter</th>
                            <th className="tableHead">Description</th>
                            <th className="tableHead">Example</th>
                            <th className="tableHead">Default</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="tableRow">
                            <td className="tableData">value</td>
                            <td className="tableData">the indicator we want to look for</td>
                            <td className="tableData">192.241.199.238</td>
                            <td className="tableData"></td>
                        </tr>
                        <tr className="tableRow">
                            <td className="tableData">Start</td>
                            <td className="tableData">
                                Start of the time period. The format can be either a date in ISO
                                format or a relative start (e.g: now-7d)
                            </td>
                            <td className="tableData">2022-09-31, now-2d, etc. </td>
                            <td className="tableData">Now-30d</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p className="fieldname">Response Fields</p>
            <ul className="variables">
                <p className="subfield">The STIX Bundle contains the below fields:</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object, it must be
                    "bundle" in this class
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the Bundle
                </li>
                <li className="italic">
                    <span className="bold">objects:</span>a list of STIX Objects
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">The STIX Indicator Object has the following fields:</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object. In this
                    case, it must be "indicator"
                </li>
                <li className="italic">
                    <span className="bold">spec_version:</span>the version of the STIX
                    specification used to represent this object. The value of this
                    property must be 2.1
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the indicator
                </li>
                <li className="italic">
                    <span className="bold">created:</span>the time at which the STIX
                    Indicator Object was originally created
                </li>
                <li className="italic">
                    <span className="bold">modified:</span>the time that this particular
                    version of the STIX Object was last modified
                </li>
                <li className="italic">
                    <span className="bold">name:</span>the name used to identify the
                    indicator
                </li>
                <li className="italic">
                    <span className="bold">description:</span>a description that provides
                    more details and context about the indicator
                </li>
                <li className="italic">
                    <span className="bold">pattern:</span>the list of the malicious IPs,
                    URLs, domain names or file hashes
                </li>
                <li className="italic">
                    <span className="bold">pattern_type:</span>the pattern language used
                    in this indicator, which is always "stix"
                </li>
                <li className="italic">
                    <span className="bold">pattern_version:</span>the version of the
                    pattern language that is used in this indicator
                </li>
                <li className="italic">
                    <span className="bold">valid_from:</span>the time from which this
                    Indicator is considered a valid indicator of the behaviors it
                    represents
                </li>
                <li className="italic">
                    <span className="bold">valid_until:</span> the expiration time of the indicator (optional)
                </li>
                <li className="italic">
                    <span className="bold">confidence:</span>an integer that represents
                    the level of confidence of the IPs, URLs or domain names in this list.
                    The confidence levels used are the ones defined in STIX 2.1 Confidence
                    Scales.
                </li>
                <li className="italic">
                    <span className="bold">kill_chain_phases:</span>describes the various
                    phases of the kill chain that the attacker undertakes
                </li>
                <li className="italic">
                    <span className="bold">labels:</span>labels or tags associated with
                    the indicator (e.g., malicious activity)
                </li>
                <li className="italic">
                    <span className="bold">external_resources:</span>External references
                    are used to describe pointers to information represented outside of
                    STIX. It is used here to point out the original source of the IoC. It
                    has 2 sub-fields:
                </li>
                <li className="italic tab">
                    <span className="bold">source_name:</span>the source of the indicator
                </li>
                <li className="italic tab">
                    <span className="bold">url:</span>a URL link that conatins a
                    description about the source
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">
                    If the indicator is of the type ipv4-addr, the object may also include
                    the following geolocation information:
                </p>
                <li className="italic">
                    <span className="bold">x_as_org:</span>name of the Autonomous System
                    organization
                </li>
                <li className="italic">
                    <span className="bold">x_asn:</span>AS number
                </li>
                <li className="italic">
                    <span className="bold">x_city_name:</span>location of the IP address
                </li>
                <li className="italic">
                    <span className="bold">x_country_code:</span>country code
                </li>
                <li className="italic">
                    <span className="bold">x_country_name:</span>country name
                </li>
                <li className="italic">
                    <span className="bold">x_latitude:</span>coordinates
                </li>
                <li className="italic">
                    <span className="bold">x_longitude:</span>coordinates
                </li>
                <li className="italic">
                    <span className="bold">x_postal_code:</span>postal/zip code
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">
                    Each Indicator can be related to the following STIX objects as well
                </p>
                <li className="italic">
                    <span className="bold">Sighting:</span>with further information such
                    as when the indicator was first seen, last seen, and how many times. This object is always present
                </li>
                <li className="italic">
                    <span className="bold">Attack Pattern:</span>it contains further
                    information on the tactic used by the attacker
                </li>
                <li className="italic">
                    <span className="bold">Marking Definition:</span>it contains the TLP label (e.g. TLP:AMBER)
                </li>
            </ul>

            <p className="tableNote">
                The corresponding value for each level is summarized in the following
                table:
            </p>
            <table className="table">
                <thead>
                    <tr className="tableRow">
                        <th className="tableHead">Confidence Label</th>
                        <th className="tableHead">STIX Confidence Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="tableRow">
                        <td className="tableData">None</td>
                        <td className="tableData">0</td>
                    </tr>
                    <tr className="tableRow">
                        <td className="tableData">Low</td>
                        <td className="tableData">15</td>
                    </tr>
                    <tr className="tableRow">
                        <td className="tableData">Medium</td>
                        <td className="tableData">50</td>
                    </tr>
                    <tr className="tableRow">
                        <td className="tableData">High</td>
                        <td className="tableData">85</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SingleIndicator;