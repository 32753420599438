export const COLORS = [
    /**
     * Pure brand colors.
     */
    '#002f70',
    '#415464',
    '#2a55a6',
    '#d85204',
    '#009dea',
    /**
     * Tint #2 colors.
     */
    '#33598d',
    '#677683',
    '#5577b8',
    '#e89768',
    '#33b1ee',
    /**
     * Tint #3 colors.
     */
    '#6682a9',
    '#8d98a2',
    '#7f99ca',
    '#efba9b',
    '#66C4F2',
];

export const DONUT_COLORS = [
    /**
     * Same colors as above, but with a different order for better contrast.
     */
    '#002f70',
    '#d85204',
    '#2a55a6',
    '#009dea',
    '#677683',
    '#5577b8',
    '#e89768',
    '#415464',
    '#33b1ee',
    '#6682a9',
    '#8d98a2',
    '#7f99ca',
    '#efba9b',
    '#33598d',
    '#66C4F2',
];