import { useState } from "react"
import { useSelector } from "react-redux"
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import VulnTableRow from "./VulnTableRow"
import VulnTableRowExpanded from "./VulnTableRowExpanded"

const VulnerabilityTable = ({ sorting, applySorting, data }) => {
    const { threat_dashboard, isSuccess } = useSelector((state) => state.data)
    const [expanded, setExpanded] = useState({ 'isExpanded': false, 'cve': null })

    if (isSuccess && threat_dashboard) {
        const showExpandedView = (cve) => {
            setExpanded({ 'isExpanded': !expanded.isExpanded, 'cve': cve })
            document.getElementById(cve).classList.toggle("hidden")
        }

        return (
            <div className='box'>
                <div className='vuln_table'>
                    <div className="vuln_table_head" onClick={() => applySorting("date", !sorting.ascending)}>
                        <p>Date Found</p>
                        {sorting.field === "date" && sorting.ascending === true ? <FaSortUp /> : sorting.field === "date" && sorting.ascending === false ? <FaSortDown /> : <FaSort />}
                    </div>
                    <div className="vuln_table_head" onClick={() => applySorting("cve", !sorting.ascending)}>
                        <p>CVE ID</p>
                        {sorting.field === "cve" && sorting.ascending === true ? <FaSortUp /> : sorting.field === "cve" && sorting.ascending === false ? <FaSortDown /> : <FaSort />}
                    </div>
                    <div className="vuln_table_head" onClick={() => applySorting("vendor", !sorting.ascending)}>
                        <p>Vendor</p>
                        {sorting.field === "vendor" && sorting.ascending === true ? <FaSortUp /> : sorting.field === "vendor" && sorting.ascending === false ? <FaSortDown /> : <FaSort />}
                    </div>
                    <div className="vuln_table_head" onClick={() => applySorting("cvss_score", !sorting.ascending)}>
                        <p>CVSS Score</p>
                        {sorting.field === "cvss_score" && sorting.ascending === true ? <FaSortUp /> : sorting.field === "cvss_score" && sorting.ascending === false ? <FaSortDown /> : <FaSort />}
                    </div>
                    <div className="vuln_table_head" onClick={() => applySorting("bundle", !sorting.ascending)}>
                        <p>Bundle Name</p>
                        {sorting.field === "bundle" && sorting.ascending === true ? <FaSortUp /> : sorting.field === "bundle" && sorting.ascending === false ? <FaSortDown /> : <FaSort />}
                    </div>
                    {data.map((row) => (
                        <>
                            <VulnTableRow data={row} showExpandedView={showExpandedView} expanded={expanded} />
                            <VulnTableRowExpanded data={row} showExpandedView={showExpandedView} />
                        </>
                    ))}
                </div>
            </div>
        )
    }
}

export default VulnerabilityTable