import { FaCheck } from "react-icons/fa";

const GreyCheck = () => {
  return (
    <div className="greyCheck">
      <FaCheck />
    </div>
  );
};

export default GreyCheck;
