import { useSelector } from 'react-redux'
import Spinner from '../components/base/Spinner'
import Header from '../components/base/Header'
import ThreatActorTable from "../components/ThreatDashboard/ThreatActorTable"
import WorldMap from '../components/ThreatDashboard/WorldMap'
import LargeBarWrapper from "../components/ThreatDashboard/LargeBarWrapper"
import LargeStat from "../components/ThreatDashboard/LargeStat"
import SmallBarWrapper from "../components/ThreatDashboard/SmallBarWrapper"
import BarWrapper from "../components/Cyberdash/BarWrapper"
import { useParams } from 'react-router-dom'
import Disclaimer from '../components/base/Disclaimer'

const ThreatActor = () => {
    const { threat_briefs, threat_dashboard, isLoading, isSuccess } = useSelector(
        (state) => state.data
    )

    const { id } = useParams()

    if (id) {
        document.querySelector(id).scrollIntoView()
    }

    if (isLoading) {
        return <Spinner />
    }

    if (isSuccess && threat_dashboard && threat_briefs) {
        return (
            <main>
                <Header text={"Threat Actor Dashboard"} />
                <div className='Grid'>
                    <WorldMap data={threat_dashboard['2Map']} />
                    {/* <WorldMap data={threat_dashboard['3Map']} /> */}
                    <LargeBarWrapper data={threat_dashboard['5Bar']} />
                    <LargeBarWrapper data={threat_dashboard['6Bar']} />
                    <LargeStat data={threat_dashboard['1Stat']} />
                    <SmallBarWrapper data={threat_dashboard['7Bar']} />
                    <BarWrapper data={threat_dashboard['2Bar']} />
                    <ThreatActorTable data={threat_dashboard['1Table']} />
                    <LargeBarWrapper data={threat_dashboard['9Bar']} />
                    <Disclaimer />
                </div>
            </main>
        )
    }
}

export default ThreatActor