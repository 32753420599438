import country_list from "./countries"

const CountryInput = ({ country, onChange }) => {
    return (
        <div className='fieldContainer'>
            <select name="country" className="input" value={country} required onChange={onChange}>
                <option value={"Choose Country"}>Choose Country</option>
                {country_list.map((country) =>
                    <option value={country.code}>{country.name}</option>
                )}
            </select>
        </div>
    )
}

export default CountryInput