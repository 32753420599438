function BannerInfo() {
  const scrollLatest = () => {
    document
      .getElementById("ResearchFeed")
      .scrollIntoView({ behavior: "smooth" });
  };

  const scrollIceFall = () => {
    document.getElementById("icefall").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="BannerInfo">
      <section className="links">
        <button className="btn homeBtn" onClick={scrollLatest}>
          Latest Vedere Labs Reports
        </button>
        <button className="btn homeBtn" onClick={scrollIceFall}>
          OT:ICEFALL
        </button>
      </section>
    </div>
  );
}

export default BannerInfo;
