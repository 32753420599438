import Info from "./Info";

function ResearchFeed(props) {
  if (props.data == null) {
    return (
      <div className='ResearchFeed'>
        <p>Null</p>
      </div>
    )
  }

  const { data, title, tooltip } = props.data;

  return (
    <div className="ResearchFeed" id="ResearchFeed">
      <Info description={tooltip} />
      <header>
        <h2>{title.toUpperCase()}</h2>
      </header>
      <section>
        <table>
          <tbody>
            {data.map((datum) => (
              <tr key={datum.title}>
                <td width="20%">{datum.date}</td>
                <td width="80%">
                  <h3>{datum.title}</h3>
                  {datum.cve_short_title}
                  <p>
                    {datum.summary}{" "}
                    <a target="_blank" rel="noreferrer" href={datum.url}>
                      Read More
                    </a>
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default ResearchFeed;
