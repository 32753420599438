import { FaCheck } from "react-icons/fa";

const GreenCheck = () => {
  return (
    <div className="greenCheck">
      <FaCheck />
    </div>
  );
};

export default GreenCheck;
