/**
 * @param {number} longNumber - The number to abbreviated into words
 * @returns {string} The abbreviated number
 */
export const makeShort = (longNumber) => {
    let text = longNumber;

    if (longNumber >= 1000 && longNumber < 10000) {
        text = (longNumber / 1000).toFixed(2) + ' K';
    } else if (longNumber >= 10000 && longNumber < 100000) {
        text = (longNumber / 1000).toFixed(1) + ' K';
    } else if (longNumber >= 100000 && longNumber < 1000000) {
        text = (longNumber / 1000).toFixed(0) + ' K';
    } else if (longNumber >= 1000000 && longNumber < 1000000000) {
        text = (longNumber / 1000000).toFixed(2) + ' Mil';
    } else if (longNumber >= 1000000000) {
        text = Math.floor(longNumber / 1000000000) + ' Bil';
    }

    return text;
}

/**
 * @param {number} value - The number to convert
 * @param {number} digits - The number of decimal places to show
 * @param {boolean} limit - whether or not small decimals should be limited to '< 0.1%'
 * @returns {string} The number in percentage form, with decimal place and % symbol
 */
export const makePercent = (value, digits = 0, limit=true) => {
    value = Number(value);

    if (value < 0.1 && digits > 0 && limit) {
        return '< 0.1%'
    }

    return value.toFixed(digits) + '%';
}