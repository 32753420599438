import { MapContainer, TileLayer, Circle, Tooltip } from 'react-leaflet'
import Info from '../Cyberdash/Info'

const WorldMap = ({ data }) => {
    if (data == null) {
        return (
            <div className='mapWidget'>
                <p>Null</p>
            </div>
        )
    }

    return (
        <div className="MapWrapper">
            <header>
                <h2 className="widgetName">{data.title.toUpperCase()}</h2>
            </header>
            <Info description={data.tooltip} />

            <MapContainer center={[51.505, -0.09]} zoom={3} scrollWheelZoom={false} style={{ "zIndex": 1 }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {data.data.map((loc, idx) => {
                    return (<Circle key={idx} center={[loc.geoip.latitude, loc.geoip.longitude]} radius={loc.counter * 5000}>
                        <Tooltip sticky>Country: {loc.geoip.country_code2}<br />{loc.counter.toFixed(1)} %</Tooltip>
                    </Circle>)
                })}
            </MapContainer>
        </div>
    )
}

export default WorldMap