import axios from "axios";

const API_URL = "https://feed.vederelabs.com/feedservice/api/";

const api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})

// Function to find a certain cookie
const findCookie = (cookieName) => {
    let name = cookieName + "=";

    let decodedCookie = decodeURIComponent(document.cookie);

    let cookies = decodedCookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        let c = cookies[i];

        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
};

// Register new User
// 
// userData object fields {
//      name                    string,
//      last_name               string,
//      company                 string,
//      email                   string,
//      password                string,
//      is_privacy_accepted     boolean,
//      captcha_token           string,
// }
const register = async (userData) => {
    const response = await api.post("users/", userData);

    if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
}

// Login User
// 
// userData fields {
//      email       string,
//      password    string,
// }
const login = async (userData) => {
    const response = await api.post("auth/login", userData);

    if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
}

// Logout current user
const logout = async () => {
    const response = await api.post("auth/logout");

    // We remove local storage even if the status is not 200
    localStorage.removeItem("user");

    return response.data;
}

// Update User's password
// 
// userData fields {
//      name                    string,
//      last_name               string,
//      company                 string,
//      email                   string,
//      FS_CRSF_TOKEN_COOKIE    string,
//      FS_CRSF_TOKEN_HEADER    string,
//      current_password        string(optional),
//      password                string(optional),
//      api_token_request       boolean(optional),
// }
const update = async (userData) => {
    const request = axios.create({
        withCredentials: true,
        baseURL: API_URL,
    });

    request.defaults.headers.common["X-XSRF-TOKEN"] = findCookie("XSRF-TOKEN");

    const response = await request.put("users/me", userData);

    if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
}

// Return current user's information
const getUser = async () => {
    const response = await api.get("users/me");

    if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
}

// Initiate password reset
// 
// userData fields {
//      email   string
// }
const forgotPassword = async (userData) => {
    const response = await api.post("auth/forgot", userData)

    return response.data
}

// Reset user's password
// 
// userData fields {
//      password    string,
//      token       string,
// }
const resetPassword = async (userData) => {
    const response = await api.post("users/password_reset", userData);

    return response.data;
}

const showPassword = () => {
    const elements = document.getElementsByClassName("passQry");

    for (let i = 0; i < elements.length; i++) {
        const type =
            elements[i].getAttribute("type") === "password" ? "text" : "password";

        elements[i].setAttribute("type", type);
    }
};

const authService = {
    register,
    login,
    logout,
    update,
    getUser,
    forgotPassword,
    resetPassword,
    showPassword
}

export default authService