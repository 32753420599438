import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dataService from "./dataService";

const initialState = {
    latest_feed: null,
    threat_briefs: null,
    threat_dashboard: null,
    threat_intel: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

export const getLatestFeed = createAsyncThunk(
    "data/latest_feed",
    async (thunkAPI) => {
        try {
            return await dataService.getLatestFeed();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getThreatBriefs = createAsyncThunk(
    "data/threat_briefs",
    async (thunkAPI) => {
        try {
            return await dataService.getThreatBriefs();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getThreatDashboard = createAsyncThunk(
    "data/threat_dashboard",
    async (thunkAPI) => {
        try {
            return await dataService.getThreatDashboard();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getThreatIntel = createAsyncThunk(
    "data/threat_intel",
    async (thunkAPI) => {
        try {
            return await dataService.getThreatIntel();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLatestFeed.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLatestFeed.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.latest_feed = action.payload;
            })
            .addCase(getLatestFeed.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.latest_feed = null;
            })
            .addCase(getThreatBriefs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getThreatBriefs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.threat_briefs = action.payload;
            })
            .addCase(getThreatBriefs.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.threat_briefs = null;
            })
            .addCase(getThreatDashboard.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getThreatDashboard.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.threat_dashboard = action.payload;
            })
            .addCase(getThreatDashboard.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.threat_dashboard = null;
            })
            .addCase(getThreatIntel.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getThreatIntel.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.threat_intel = action.payload;
            })
            .addCase(getThreatIntel.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.threat_intel = null;
            })
    }
})

export const { reset } = dataSlice.actions;
export default dataSlice.reducer;