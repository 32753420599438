import React from "react";

const Registered = () => {
  return (
    <div className="form-box">
      <p className="header">
        Thank you for registering to our Threat Feed Service!
      </p>
      <p className="description-text">
        We have received your request and will process it shortly. When you have
        been approved you will recieve an email with instructions on how to
        query our API.
      </p>
    </div>
  );
};

export default Registered;
