import us_states from './us_states.js'

const USStateInput = ({ state, onChange }) => {
    return (
        <div className='fieldContainer'>
            <select name="state" className="input" value={state} required onChange={onChange}>
                <option value={"Choose State"}>Choose State</option>
                {us_states.map((states) =>
                    <option value={states.abbreviation}>{states.name}</option>
                )}
            </select>
        </div>
    )
}

export default USStateInput