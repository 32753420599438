import Info from "../Cyberdash/Info"

const LargeStat = (props) => {
    const { data, title, tooltip } = props.data

    console.log(data)

    return (
        <div className="LargeStat" id="LargeStat">
            <Info description={tooltip} />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <h3>{data[0]['value']}</h3>
        </div>
    )
}

export default LargeStat