import { Cell, ResponsiveContainer, Bar, BarChart, XAxis, YAxis, Label, CartesianGrid, Tooltip } from 'recharts'
import { COLORS } from '../../utils/colors'
import Info from '../Cyberdash/Info'

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">
                    {payload[0]['payload']['text']}
                    <br />
                    {payload[0]['payload']['value']}
                </p>
            </div>
        );
    }

    return null;
};

const Histogram = (props) => {
    const { data, title, tooltip } = props.data

    return (
        <div className='HistogramWrapper'>
            <Info description={tooltip} />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={data} margin={{ top: 0, right: 10, left: 0, bottom: 50 }}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        type="category"
                        dataKey="text"
                        interval={0}
                        angle={-45}
                        textAnchor="end"
                        tick={{
                            fontSize: ".75rem",
                        }}
                        axisLine={{
                            stroke: "#cccccc"
                        }}
                        tickLine={{
                            stroke: "#cccccc"
                        }}
                        height={50}
                    >
                    </XAxis>
                    <YAxis
                        dataKey="value"
                        type="number"
                        tick={{
                            fontSize: ".75rem",
                        }}
                        axisLine={{
                            stroke: "#cccccc"
                        }}
                        tickLine={{
                            stroke: "#cccccc"
                        }}
                    >
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="value" barSize={50}>
                        {data.map((datum, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

        </div>
    )
}

export default Histogram