import Info from './Info';

/**
 * Convert a number to a shorter, readable string version based on a few rules.
 * @param {number} value - number to convert.
 */
function toText(value) {
    let text = value;

    if (value >= 1000000 && value < 1000000000) {
        text = (value / 1000000).toFixed(1) + ' Million';
    } else if (value >= 1000000000) {
        text = Math.floor(value / 1000000000) + ' Billion';
    }

    return text;
}

function Stat(props) {
    const { data, title, tooltip } = props.data;

    return (
        <div className="Stat">
            <Info description={tooltip} />
            <h3>{toText(data)}</h3>
            <h2>{title.toUpperCase()}</h2>
        </div>
    )
}

export default Stat;