import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserContext from '../../context/UserContext';
import { FaBars, FaTimes, FaAngleDown, FaAngleRight } from "react-icons/fa";

const SmallNavbar = () => {
    const [signedIn, setSignedIn] = useState(true);
    const [open, setOpen] = useState(false);
    const [feedsDropdown, setFeedsDropdown] = useState(false);
    const [dashDropdown, setDashDropdown] = useState(false);

    const { user, pending, signOut } = useContext(UserContext);

    // This function will get the logged in status of the user 
    // when the page first loads and anytime there is a change 
    // to the status of pending or user
    useEffect(() => {
        if (typeof user === "object" && !Array.isArray(user) && user !== null && pending !== "pending") {
            setSignedIn(true)
        } else {
            setSignedIn(false)
        }
    }, [pending, user])

    // Function to logout the current user
    const logout = async () => {
        const res = await signOut();
        setDropdown(false);
        setSignedIn(false);

        if (res.data.detail[0].msg) {
            toast.error(res.data.detail[0].msg);
        } else {
            toast.error(res.data.detail);
        }
    }

    const closeMenus = () => {
        setOpen(false);
        setDashDropdown(false);
        setFeedsDropdown(false);
    }

    return (
        <header>
            {open ? (
                <div className='navbar'>
                    <div className="w-full">
                        <img src='icons/forescout-research-vedere-labs-white.svg' className="navbar-logo margin-top" />
                        <FaTimes className="navbarItem hover" onClick={() => closeMenus()} />
                        <Link to="/" onClick={() => closeMenus()} className="nav-menu-link hover">Home</Link>
                        <div className="nav-menu-link flex" onClick={() => setDashDropdown(!dashDropdown)}>
                            <p className="dropdown">Dashboards</p>
                            {dashDropdown ? <FaAngleDown /> : <FaAngleRight />}
                        </div>
                        {dashDropdown && (
                            <>
                                <Link to="/" onClick={() => closeMenus()} className="nav-menu-link tab">Global Cyber Intelligence Dashboard</Link>
                                <Link to="/attacks-dashboard" onClick={() => closeMenus()} className="nav-menu-link tab">Attacks Dashboard</Link>
                                <Link to="/threat-actors-dashboard" onClick={() => closeMenus()} className='nav-menu-link tab'>Threat Actors Dashboard</Link>
                                <Link to="vulnerabilities" onClick={() => closeMenus()} className='nav-menu-link tab'>Vulnerability Disclosure Dashboard</Link>
                            </>
                        )}
                        <div className='nav-menu-link' onClick={() => setFeedsDropdown(!feedsDropdown)}>
                            <p className='dropdown'>Threat Feed Service</p>
                            {feedsDropdown ? <FaAngleDown /> : <FaAngleRight />}
                        </div>
                        {feedsDropdown && (
                            <>
                                {signedIn ? (
                                    <>
                                        <Link to="/register" onClick={() => closeMenus()} className="nav-menu-link tab">Register</Link>
                                        <Link to="/" onClick={() => logout()} className="nav-menu-link tab">Sign Out</Link>
                                        <Link to="/profile" onClick={() => closeMenus()} className="nav-menu-link tab">Profile</Link>
                                        <Link to="/documentation" onClick={() => closeMenus()} className="nav-menu-link tab">Documentation</Link>
                                    </>
                                ) : (
                                    <>
                                        <Link to="/register" onClick={() => closeMenus()} className="nav-menu-link tab">Register</Link>
                                        <Link to="/signin" onClick={() => closeMenus()} className="nav-menu-link tab">Sign In</Link>
                                    </>
                                )}
                            </>
                        )}
                        <Link to="/copilot" onClick={() => closeMenus()} className="nav-menu-link">Copilot Integration</Link>
                        <a href="https://www.forescout.com/research-newsletter-subscription-lp/" target="_blank" onClick={() => closeMenus()} className="nav-menu-link">Subscribe to the Vedere Labs Newsletter</a>
                    </div>
                </div>
            ) : (
                <div className="navbar">
                    <img src='icons/forescout-research-vedere-labs-white.svg' className="navbar-logo" />
                    <FaBars className="menu-icon hover" onClick={() => setOpen(true)} />
                </div>
            )
            }
        </header >
    )
}

export default SmallNavbar