import industries from './industries.js'

const IndustryInput = ({ industry, onChange }) => {
    return (
        <div className="fieldContainer">
            <select name="industry" className="input" value={industry} onChange={onChange}>
                <option value={"Choose industry"}>Choose Your Industry</option>
                {industries.map((industry) =>
                    <option value={industry}>{industry}</option>
                )}
            </select>
        </div>
    )
}

export default IndustryInput