import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    Bar,
    Cell,
    LabelList,
    Tooltip,
    Legend
} from 'recharts';
import { COLORS } from '../../utils/colors';
import { useMemo } from 'react';
import Info from './Info';

/**
 * Make some changes to the raw data for better appearance.
 * @param {Array} data 
 */
function cleanData(data) {
    return (
        data.map((d) => (
            {
                key: d.key,
                value: d.value == Math.floor(d.value) ? d.value : d.value.toFixed(1),
            }
        ))
    );
}

const CustomLegend = ({ payload }) => {
    return (
        <ul className='CustomLegend'>
            {payload.map((entry, index) => (
                <li className='LegendItem' key={`item-${index}`} style={{ color: COLORS[index % COLORS.length], fontWeight: 700 }}>{entry.key}: {entry.value}</li>
            ))}
        </ul>
    )
}

function BarWrapper(props) {
    const { data, title, tooltip, x_label } = props.data;
    const barData = useMemo(() => (cleanData(data)), [data])

    return (
        <div className="BarWrapper">
            <Info description={tooltip} />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={barData}
                >
                    <XAxis type="number" hide domain={[0, "auto"]}>
                        <LabelList
                            value={x_label}
                            position="insideBottom"
                        />
                    </XAxis>
                    <YAxis
                        dataKey="key"
                        type="category"
                        hide
                    >
                    </YAxis>
                    <Bar dataKey="value" barSize={35}>
                        {data.map((datum, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                                fontWeight={700}
                            />
                        ))}
                        <LabelList dataKey="value" position="right" />
                    </Bar>
                    <Tooltip cursor={{ fill: 'transparent' }} />
                    <Legend dataKey="shortKey" payload={barData} content={<CustomLegend />} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default BarWrapper;