import { useState } from 'react';

function ThreatActorInfo() {
    const [active, setActive] = useState(false);

    const toggleTooltip = () => {
        setActive(active => !active);
    }

    return (
        <div className="Info" onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}>
            <div className="icon">i</div>
            {active && (
                <div className="tooltip">
                    <p>Latest updates on Threat Actors analyzed by Vedere Labs. The data is the result of internal research activities powered by the following external sources: <a href="https://attack.mitre.org/groups/" target="_blank">MITRE</a> and <a href="https://github.com/MISP/misp-galaxy/blob/main/clusters/threat-actor.json" target="_blank">MISP</a>.</p>
                </div>
            )}
        </div>
    )
}

export default ThreatActorInfo;