import { toast } from "react-toastify";
import { FaTimes, FaCopy, FaExpandAlt } from "react-icons/fa";

const URLs = () => {
  const showExample = (show) => {
    document.getElementById(show).classList.remove("hidden");
  };

  const hideExample = (hide) => {
    document.getElementById(hide).classList.add("hidden");
  };

  const copyExample = () => {
    navigator.clipboard.writeText(
      "import requests\r\nurl = 'https://api.feeds.vederelabs.com/feedservice/api/indicators/?type=url'\r\nheaders = {\r\n\t'api-key' : '{your_api_key}'\r\n}\r\nresponse = requests.get(url, headers=headers)\r\nprint(response.text)"
    );
    toast.success("API request has been copied!", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  return (
    <div className="column">
      <div className="desc-section">
        <h2>URL Indicators</h2>
        <p>
          This API query call will enable you to get the list of malicious URLs.
          A STIX Bundle is a collection of arbitrary STIX Objects grouped together in a single
          container. A Bundle does not have any semantic meaning and the objects
          contained within the Bundle are not considered related by virtue of
          being in the same Bundle.
        </p>
      </div>
      <div className="example">
        <div className="codeHeader">
          <p>API Request (Python)</p>
          <FaCopy className="icon" onClick={() => copyExample()} />
        </div>
        <div className="code">
          <p className="codeLine">
            <span className="highlight">import</span> requests
          </p>
          <p className="codeLine">
            url <span className="highlight">= "</span>
            <span className="quote">
              https://api.feeds.vederelabs.com/feedservice/api/indicators/?type=url&start=now-7d
            </span>
            <span className="highlight">"</span>
          </p>
          <p className="codeLine">
            headers <span className="highlight">= </span> &#123;
          </p>
          <p className="codeLine tab">
            <span className="highlight">'api-key' </span>:
            <span className="highlight"> '&#123;your_api_key&#125;'</span>
          </p>
          <p className="codeLine">&#125;</p>
          <p className="codeLine">
            response <span className="highlight">=</span> requests.get(url,
            headers=headers)
          </p>
          <p className="codeLine">
            <span className="secHighlight">print</span>(response.text)
          </p>
        </div>
      </div>
      <div className="responses">
        <div className="response" onClick={() => showExample("code1")}>
          <div className="greenCircle"></div>
          <p>HTTP code 200 - Successful Response</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code1">
          <FaTimes className="close" onClick={() => hideExample("code1")} />
          <p>&#123;</p>
          <p className="tab">"type": "bundle",</p>
          <p className="tab">
            "id": "bundle--04469d79-6cbf-4d38-9ce2-1316a1c4fd13",
          </p>
          <p className="tab">"objects": [</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "indicator",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "indicator--cd3bb847-d994-408a-8d59-4cbafb067748",
          </p>
          <p className="tab3">"created": "2022-12-05T10:39:22.054063Z",</p>
          <p className="tab3">"modified": "2022-12-05T10:39:22.054063Z",</p>
          <p className="tab3">"name": "Malicious URL",</p>
          <p className="tab3">
            "description": "Detected malicious URL from the VedereLabs Adversary
            Engagement Environment.",
          </p>
          <p className="tab3">
            "pattern": "[domain-name:value = 'amaliciousdomain.com/t.sh']",
          </p>
          <p className="tab3">"pattern_type": "stix",</p>
          <p className="tab3">"pattern_version": "2.1",</p>
          <p className="tab3">"valid_from": "2022-12-04T17:30:46.520497Z",</p>
          <p className="tab3">"valid_until": "2023-01-03T17:30:46.520497Z",</p>
          <p className="tab3">"kill_chain_phases": [</p>
          <p className="tab4">&#123;</p>
          <p className="tab5">"kill_chain_name": "mitre-attack",</p>
          <p className="tab5">"phase_name": "execution"</p>
          <p className="tab4">&#125;</p>
          <p className="tab3">],</p>
          <p className="tab3">"labels": [</p>
          <p className="tab4">"malicious-activity"</p>
          <p className="tab3">],</p>
          <p className="tab3">"confidence": 85,</p>
          <p className="tab3">"external_references": [</p>
          <p className="tab4">&#123;</p>
          <p className="tab5">"source_name": "Vedere Labs",</p>
          <p className="tab5">
            "url": "https://www.forescout.com/research-labs/"
          </p>
          <p className="tab4">&#125;</p>
          <p className="tab3">]</p>
          <p className="tab2">&#125;,</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "sighting",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "sighting--53d0cbfc-ee45-495d-ba27-8b93b21d1be5",
          </p>
          <p className="tab3">"created": "2022-12-05T10:39:22.055519Z",</p>
          <p className="tab3">"modified": "2022-12-05T10:39:22.055519Z</p>
          <p className="tab3">"first_seen": "2022-12-04T17:30:46.520497Z",</p>
          <p className="tab3">"last_seen": "2022-12-04T17:30:46.520497Z",</p>
          <p className="tab3">"count": 1,</p>
          <p className="tab3">
            "sighting_of_ref":
            "indicator--cd3bb847-d994-408a-8d59-4cbafb067748",
          </p>
          <p className="tab2">&#125;,</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "attack-pattern",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "attack-pattern--be2e9457-e23f-4d0b-9c9b-7748cd3ff995",
          </p>
          <p className="tab3">"created": "2022-12-05T12:49:25.133098Z",</p>
          <p className="tab3">"modified": "2022-12-05T12:49:25.133098Z",</p>
          <p className="tab3">"name": "Command and Scripting Interpreter",</p>
          <p className="tab3">
            "description": "Adversaries may abuse command and script
            interpreters to execute commands, scripts, or binaries. These
            interfaces and languages provide ways of interacting with computer
            systems and are a common feature across many different platforms.
            Most systems come with some built-in command-line interface and
            scripting capabilities, for example, macOS and Linux distributions
            include some flavor of [Unix
            Shell](https://attack.mitre.org/techniques/T1059/004) while Windows
            installations include the [Windows Command
            Shell](https://attack.mitre.org/techniques/T1059/003) and
            [PowerShell](https://attack.mitre.org/techniques/T1059/001).\n\nThere
            are also cross-platform interpreters such as
            [Python](https://attack.mitre.org/techniques/T1059/006), as well as
            those commonly associated with client applications such as
            [JavaScript](https://attack.mitre.org/techniques/T1059/007) and
            [Visual
            Basic](https://attack.mitre.org/techniques/T1059/005).\n\nAdversaries
            may abuse these technologies in various ways as a means of executing
            arbitrary commands. Commands and scripts can be embedded in [Initial
            Access](https://attack.mitre.org/tactics/TA0001) payloads delivered
            to victims as lure documents or as secondary payloads downloaded
            from an existing C2. Adversaries may also execute commands through
            interactive terminals/shells, as well as utilize various [Remote
            Services](https://attack.mitre.org/techniques/T1021) in order to
            achieve remote Execution.(Citation: Powershell Remote
            Commands)(Citation: Cisco IOS Software Integrity Assurance - Command
            History)(Citation: Remote Shell Execution in Python)",
          </p>
          <p className="tab3">"external_references": [</p>
          <p className="tab4">&#123;</p>
          <p className="tab5">"source_name": "mitre-attack",</p>
          <p className="tab5">
            "url": "https://attack.mitre.org/techniques/T1059",
          </p>
          <p className="tab5">"external_id": "T1059"</p>
          <p className="tab4">&#125;</p>
          <p className="tab3">]</p>
          <p className="tab2">&#125;,</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "relationship",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "relationship--89e13662-6950-4d99-b646-6c1cc659d04c",
          </p>
          <p className="tab3">"created": "2022-12-05T10:39:22.056366Z",</p>
          <p className="tab3">"modified": "2022-12-05T10:39:22.056366Z",</p>
          <p className="tab3">"relationship_type": "indicates",</p>
          <p className="tab3">
            "source_ref": "indicator--cd3bb847-d994-408a-8d59-4cbafb067748",
          </p>
          <p className="tab3">
            "target_ref":
            "attack-pattern--931016bd-6d78-48c3-9436-46dfe0b3c897",
          </p>
          <p className="tab2">&#125;</p>
          <p className="tab">]</p>
          <p>&#125;</p>
        </div>
        <div className="response" onClick={() => showExample("code2")}>
          <div className="redCircle"></div>
          <p>HTTP code 401 - Not authenticated</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code2">
          <FaTimes className="close" onClick={() => hideExample("code2")} />
          <p>&#123;"detail": "Not Authenticated"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code3")}>
          <div className="redCircle"></div>
          <p>
            HTTP code 403 - User has not permissions to access the requested
            resource
          </p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code3">
          <FaTimes className="close" onClick={() => hideExample("code3")} />
          <p>
            &#123;"detail": "User has not permissions to access the requested
            resource"&#125;
          </p>
        </div>

        <div className="response" onClick={() => showExample("code4")}>
          <div className="redCircle"></div>
          <p>HTTP code 404 - Not found</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code4">
          <FaTimes className="close" onClick={() => hideExample("code4")} />
          <p>&#123;"detail": "No valid indicators"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code5")}>
          <div className="redCircle"></div>
          <p>HTTP code 422 - Validation error</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code5">
          <FaTimes className="close" onClick={() => hideExample("code5")} />
          <p>&#123;"detail": "Validation error"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code6")}>
          <div className="redCircle"></div>
          <p>HTTP code 500 - Error processing the request</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code6">
          <FaTimes className="close" onClick={() => hideExample("code6")} />
          <p>&#123;"detail": "Internal error"&#125; </p>
        </div>
      </div>
      <div className="tableOverflow">
        <table className="table">
          <thead>
            <tr className="tableRow">
              <th className="tableHead">Parameter</th>
              <th className="tableHead">Description</th>
              <th className="tableHead">Example</th>
              <th className="tableHead">Default</th>
            </tr>
          </thead>
          <tbody>
            <tr className="tableRow">
              <td className="tableData">Start</td>
              <td className="tableData">
                Start of the time period. The format can be either a date in ISO
                format or a relative start (e.g: now-7d)
              </td>
              <td className="tableData">2022-09-31, now-2d, etc. </td>
              <td className="tableData">Now-30d</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className="fieldname">Response Fields</p>
      <ul className="variables">
        <p className="subfield">STIX Bundle</p>
        <li className="italic">
          <span className="bold">type:</span>type of the STIX Object, it must be
          "bundle" in this class
        </li>
        <li className="italic">
          <span className="bold">id:</span>the ID of the Bundle
        </li>
        <li className="italic">
          <span className="bold">objects:</span>a list of STIX Objects
        </li>
      </ul>
      <ul className="variables">
        <p className="subfield">STIX Indicator Object</p>
        <li className="italic">
          <span className="bold">type:</span>type of the STIX Object. In this
          case, it must be "indicator"
        </li>
        <li className="italic">
          <span className="bold">spec_version:</span>the version of the STIX
          specification used to represent this object. The value of this
          property must be 2.1
        </li>
        <li className="italic">
          <span className="bold">id:</span>the ID of the indicator
        </li>
        <li className="italic">
          <span className="bold">created:</span>the time at which the STIX
          Indicator Object was originally created
        </li>
        <li className="italic">
          <span className="bold">modified:</span>the time that this particular
          version of the STIX Object was last modified
        </li>
        <li className="italic">
          <span className="bold">name:</span>the name used to identify the
          indicator
        </li>
        <li className="italic">
          <span className="bold">description:</span>a description that provides
          more details and context about the indicator
        </li>
        <li className="italic">
          <span className="bold">pattern:</span>the list of the malicious domain
          names addresses
        </li>
        <li className="italic">
          <span className="bold">pattern_type:</span>the pattern language used
          in this indicator, which is always "stix"
        </li>
        <li className="italic">
          <span className="bold">pattern_version:</span>the version of the
          pattern language that is used in this indicator
        </li>
        <li className="italic">
          <span className="bold">valid_from:</span>the time from which this
          Indicator is considered a valid indicator of the behaviors it
          represents
        </li>
        <li className="italic">
          <span className="bold">valid_until:</span>the expiration time of the
          indicator (optional)
        </li>
        <li className="italic">
          <span className="bold">confidence:</span>an integer that represents
          the level of confidence of the IPs, URLs or domain names in this list.
          The confidence levels used are the ones defined in STIX 2.1 Confidence
          Scales.
        </li>
        <li className="italic">
          <span className="bold">kill_chain_phases:</span>describes the various
          phases of the kill chain that the attacker undertakes
        </li>
        <li className="italic">
          <span className="bold">labels:</span>labels or tags associated with
          the indicator (e.g., malicious activity)
        </li>
        <li className="italic">
          <span className="bold">external_resources:</span>External references
          are used to describe pointers to information represented outside of
          STIX. It is used here to point out the original source of the IoC. It
          has 2 sub-fields:
        </li>
        <li className="italic tab">
          <span className="bold">source_name:</span>the source of the indicator
        </li>
        <li className="italic tab">
          <span className="bold">url:</span>a URL link that conatins a
          description about the source
        </li>
        <li className="italic">
          <span className="bold">object_marking_refs:</span>this optional field refers to a possible marking definition object specifying the Traffic Light Protocol (TLP) lable (e.g. TLP:AMBER)
        </li>
      </ul>
      <ul className="variables">
        <p className="subfield">
          Each Indicator can be related to the following STIX objects as well
        </p>
        <li className="italic">
          <span className="bold">Sighting:</span>with further information such
          as when the indicator was first seen, last seen, and how many times
        </li>
        <li className="italic">
          <span className="bold">Attack Pattern:</span>it contains further
          information on the tactic used by the attacker
        </li>
        <li className="italic">
          <span className="bold">Malware:</span>it contains information on the malware and the hashes of the malware sample (included in a STIX File object). An indicater can be related multiple malware objects
        </li>
        <li className="italic">
          <span className="bold">Marking Definition:</span>it contains the TLP label (e.g. TLP:AMBER)
        </li>
      </ul>

      <p className="tableNote">
        The corresponding value for each level is summarized in the following
        table:
      </p>
      <table className="table">
        <thead>
          <tr className="tableRow">
            <th className="tableHead">Confidence Label</th>
            <th className="tableHead">STIX Confidence Value</th>
          </tr>
        </thead>
        <tbody>
          <tr className="tableRow">
            <td className="tableData">None</td>
            <td className="tableData">0</td>
          </tr>
          <tr className="tableRow">
            <td className="tableData">Low</td>
            <td className="tableData">15</td>
          </tr>
          <tr className="tableRow">
            <td className="tableData">Medium</td>
            <td className="tableData">50</td>
          </tr>
          <tr className="tableRow">
            <td className="tableData">High</td>
            <td className="tableData">85</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default URLs;
