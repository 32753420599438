const industries = [
    "Business Services",
    "Discrete Manufacturing",
    "Education",
    "Entertainment",
    "Finance",
    "Government",
    "Healthcare",
    "Minerals & Mining",
    "Oil and gas",
    "Process Manufacturing",
    "Retail",
    "Services",
    "Technology",
    "Utilities",
    "Other"
]

export default industries