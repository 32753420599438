import Header from '../base/Header'
import video from './Forescout_Copilot_subtitle_v3.mp4'

const Copilot = () => {
    return (
        <main>
            <Header text={"Microsoft Copilot for Security + Forescout Research Vedere Labs"} />
            <div className="copilot-box">
                <div className="grey-box">
                    <div className="icon-section">
                        <img src="pictures/Copilot.png" className="forescout-icon" />
                        <p className="plus">+</p>
                        <img src="pictures/Forescout.png" className="forescout-icon" />
                    </div>
                    <h3 className="para_title">Microsoft Copilot for Security + Forescout Research Vedere Labs</h3>
                    <p>Forescout Research Vedere Labs is excited to collaborate with Microsoft on their Copilot for Security initiative. This powerful tool integrates advanced large language models (LLMs) with extensive data, including Forescout Research Vedere Labs' threat intelligence and Forescout's Risk and Exposure Management. With this integration, Copilot for Security users will gain valuable insights from their assets, enriched with the latest malicious indicators identified by Vedere Labs. Analysts using Microsoft for organizational protection now have enhanced opportunities to utilize Forescout's insights. </p>
                    <h3 className="para_title">Forescout Research Vedere Labs Threat intelligence</h3>
                    <p>Forescout Research Vedere Labs research team offers a comprehensive threat intelligence feed that includes IP addresses, URLs, and file hash indicators from all monitored activities. This feed also includes information on Known Exploited Vulnerabilities and Forescout's own reported CVEs. Additionally, this service allows for lookups against domain names to check for use of Domain Generation Algorithms (DGA) or data exfiltration techniques. Vedere Lab’s extensive research covers IT, OT, IoT, and IoMT, providing valuable indicators and CVE details. Enhance value even further by using this resource with Microsoft Copilot for Security, enabling security teams to accelerate threat hunting efforts and improve operational efficiency and effectiveness. </p>
                    <p>Register <a href="https://forescout.vederelabs.com/register" className="copilot-link" target="_blank">here</a> to get an API key to retrieve vulnerabilities and indicators provided by Forescout Research Vedere Labs! </p>
                    <p>Configuration in Copilot for Security is straightforward. Go into the Copilot for Security prompt, click the Sources button and look for Forescout Research Vedere Labs Threat Intelligence Source. </p>
                    <div className="image-container">
                        <img src="pictures/copilot_prompt.png" className="instruction-images" />
                    </div>
                    <div className="image-container">
                        <img src="pictures/vederelabs_plugin.png" className="instruction-images" />
                    </div>
                    <p>Under the Value form, provide the API key associated to your profile.</p>
                    <div className="image-container">
                        <img src="pictures/value_prompt.png" className="instruction-images" />
                    </div>
                    <h3 className="para_title">Forescout Risk and Exposure Management</h3>
                    <p><a href="https://www.forescout.com/solutions/risk-and-exposure-management/" className="copilot-link" target="_blank">Forescout Risk and Exposure Management</a> (REM) provides data across all devices in a customer environment as discovered by the Forescout platform. REM gives a single view of device risk and vulnerabilities, including a timeline to track events and changes to a device risk value. Combining the contextual data from the Forescout REM platform with Microsoft Copilot for Security allows security teams to quickly assess the risk posed across their environments and prioritize work by getting clear information on their exposure to different vulnerabilities or configurations which exist. The setting of this source is equivalent to the above. The API key for REM can be configured navigating in Administration and then Integration of the product.</p>
                    <div className="image-container">
                        <img src="pictures/rem-plugin.png" className="instruction-images" />
                    </div>
                </div>
                <div className="video-container">
                    <h3>DEMO | Copilot & Forescout Research Vedere Labs</h3>
                    <video width={320} controls className="video">
                        <source src={video} type="video/mp4"></source>
                    </video>
                </div>
            </div>
        </main>
    )
}

export default Copilot