import { Link } from "react-router-dom"

const Header = ({ text }) => {
    return (
        <section className="headerSection">
            <h1 className="pageTitle">{text}</h1>
        </section>
    )
}

export default Header