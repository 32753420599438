import { useMemo } from 'react';
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    Bar,
    Cell,
    LabelList,
    Label,
    Tooltip,
    Text,
    Legend
} from 'recharts';
import { COLORS } from '../../utils/colors';
import Info from '../Cyberdash/Info';

const CustomLegend = (props) => {
    return (
        <ul>
            {
                props.payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{ color: COLORS[index % COLORS.length], fontWeight: 700 }}>{entry.key}: {entry.value}</li>
                ))
            }
        </ul>
    )
}

const SmallBarWrapper = (props) => {
    const { data, title, tooltip } = props.data;

    return (
        <div className='SmallBarWrapper'>
            <Info description={tooltip} />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={data}
                >
                    <XAxis type="number" hide />
                    <YAxis dataKey="key" type="category" hide />
                    <Bar dataKey="value" >
                        {data.map((datum, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                                fontWeight={700}
                            />
                        ))}
                        <LabelList dataKey="value" position="right" />
                    </Bar>
                    <Legend layout="vertical" payload={data} content={<CustomLegend />} />
                    <Tooltip cursor={{ fill: 'transparent' }} />
                </BarChart>
            </ResponsiveContainer>
        </div>

    )
}

export default SmallBarWrapper