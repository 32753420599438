import React from 'react'

const VulnTableRowExpanded = ({ data, showExpandedView }) => {
    return (
        <div className='vuln_table_expanded_row hidden' id={data.cve} onClick={() => showExpandedView(data.cve)}>
            <div>
                <p className="vuln_table_field bold">Product</p>
                <p className="vuln_table_field bold">Publication</p>
                <p className="vuln_table_field bold">Description</p>
            </div>
            <div>
                <p className="vuln_table_field">{data.product}</p>
                <a className="vuln_table_field" href={data.Reference} target="_blank">{data.Reference}</a>
                <p className="vuln_table_field">{data.description}</p>
            </div>
        </div>
    )
}

export default VulnTableRowExpanded