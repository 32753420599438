import { useState } from "react";
import { FaBars, FaTimes, FaAngleDown, FaAngleRight } from "react-icons/fa";
import Indicators from "../components/Documentation/Indicators";
import CVEs from "../components/Documentation/CVEs";
import VLCVEs from "../components/Documentation/VLCVEs"
import URLs from "../components/Documentation/URLs";
import IPs from "../components/Documentation/IPs";
import Domains from "../components/Documentation/Domains";
// import AllMalware from "../components/Documentation/AllMalware";
import LookupDNS from "../components/Documentation/LookupDNS";
import IndicatorRelationships from "../components/Documentation/IndicatorRelationships";
import FileHashes from "../components/Documentation/FileHashes";
import SingleIndicator from "../components/Documentation/SingleIndicator";

const Documentation = () => {
  const [active, setActive] = useState("Indicators");
  const [menu, setMenu] = useState(true);
  const [dropDown, setDropDown] = useState(false);

  const hideMenu = () => {
    setMenu(false);
  };

  const openMenu = () => {
    setMenu(!menu);
  };

  return (
    <main>
      <div className="feedservice-section">
        <h2 className="feedservice-title">Threat Feed Service</h2>
        <p className="feedservice-desc">The Threat Feed Service allows our key partners and stakeholders to consume the threat intelligence generated by Forescout Vedere Labs in a machine-readable way. The feeds provide a list of daily updated indicators of compromise including bad IPs, bad DNS, bad file hashes and URLS. It also provides a list of known exploited vulnerabilities (VL-KEV) observed via our AEE.</p>
      </div>
      <div className="subheader flex">
        <FaBars className="hover" onClick={() => openMenu()} />
        <p className="text">API Documentation</p>
      </div>
      <div className="doc">
        {menu && (
          <div className="menu">
            <div className="hide">
              <FaTimes onClick={() => hideMenu()} />
            </div>
            <p className="menuItems" onClick={() => setDropDown(!dropDown)}>
              {!dropDown ? <FaAngleRight /> : <FaAngleDown />}
              Indicators
            </p>
            {dropDown && (
              <>
                <p
                  className={`${active === "Indicators" && "active"
                    } menuItems tab2`}
                  onClick={() => setActive("Indicators")}
                >
                  All
                </p>
                <p
                  className={`${active === "SingleIndicator" && "active"
                    } menuItems tab2`}
                  onClick={() => setActive("SingleIndicator")}
                >
                  Single
                </p>
                <p
                  className={`${active === "URLs" && "active"
                    } menuItems tab2`}
                  onClick={() => setActive("URLs")}
                >
                  URLs
                </p>
                <p
                  className={`${active === "IPs" && "active"} menuItems tab2`}
                  onClick={() => setActive("IPs")}
                >
                  Malicious IPs
                </p>
                <p
                  className={`${active === "Domains" && "active"
                    } menuItems tab2`}
                  onClick={() => setActive("Domains")}
                >
                  Domain Names
                </p>
                <p
                  className={`${active === "FileHashes" && "active"
                    } menuItems tab2`}
                  onClick={() => setActive("FileHashes")}
                >
                  File Hashes
                </p>
              </>
            )}
            <p
              className={`${active === "CVEs" && "active"} menuItems`}
              onClick={() => setActive("CVEs")}
            >
              Observed Exploited CVEs
            </p>
            <p
              className={`${active === "VLCVEs" && "active"} menuItems`}
              onClick={() => setActive("VLCVEs")}
            >
              Vedere Labs Original CVEs
            </p>
            <p
              className={`${active === "LookupDNS" && "active"} menuItems`}
              onClick={() => setActive("LookupDNS")}
            >
              Lookup DNS
            </p>
            <p
              className={`${active === "IndicatorRelationships" && "active"
                } menuItems`}
              onClick={() => setActive("IndicatorRelationships")}
            >
              Indicator Relationships
            </p>
          </div >
        )}
        {
          active === "Indicators" ? (
            <Indicators />
          ) : active === "SingleIndicator" ? (
            <SingleIndicator />
          ) : active === "URLs" ? (
            <URLs />
          ) : active === "IPs" ? (
            <IPs />
          ) : active === "Domains" ? (
            <Domains />
          ) : active === "FileHashes" ? (
            <FileHashes />
          ) : active === "CVEs" ? (
            <CVEs />
          ) : active === "VLCVEs" ? (
            <VLCVEs />
          ) : active === "IndicatorRelationships" ? (
            <IndicatorRelationships />
          ) : (
            active === "LookupDNS" && <LookupDNS />
          )
        }
      </div >
    </main>
  );
};

export default Documentation;