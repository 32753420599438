import { toast } from "react-toastify";
import { FaCopy, FaExpandAlt, FaTimes } from "react-icons/fa";

const LookupDNS = () => {
  const showExample = (show) => {
    document.getElementById(show).classList.remove("hidden");
  };

  const hideExample = (hide) => {
    document.getElementById(hide).classList.add("hidden");
  };

  const copyExample = () => {
    navigator.clipboard.writeText(
      "import requests\r\nurl = 'https://api.feeds.vederelabs.com/feedservice/api/cves/'\r\nheaders = {\r\n\t'api-key' : '{your_api_key}'\r\n}\r\nresponse = requests.get(url, headers=headers)\r\nprint(response.text)"
    );
    toast.success("API request has been copied!", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  return (
    <div className="column">
      <div className="desc-section">
        <h2>Lookup DNS</h2>
        <p>
          This API query call will enable you to check if a domain name is
          suspected to use a Domain Generation Algorithm (DGA) or data
          exfiltration techniques.
        </p>
      </div>
      <div className="example">
        <div className="codeHeader">
          <p>API Request (Python)</p>
          <FaCopy className="icon" onClick={() => copyExample()} />
        </div>
        <div className="code">
          <p className="codeLine">
            <span className="highlight">import</span> requests
          </p>
          <p className="codeLine">
            url <span className="highlight">= "</span>
            <span className="quote">
              https://api.feeds.vederelabs.com/feedservice/api/lookup?dns=&#60;dns
              to check&#62;
            </span>
            <span className="highlight">"</span>
          </p>
          <p className="codeLine">
            headers <span className="highlight">= </span> &#123;
          </p>
          <p className="codeLine tab">
            <span className="highlight">'api-key' </span>:
            <span className="highlight"> '&#123;your_api_key&#125;'</span>
          </p>
          <p className="codeLine">&#125;</p>
          <p className="codeLine">
            response <span className="highlight">=</span> requests.get(url,
            headers=headers)
          </p>
          <p className="codeLine">
            <span className="secHighlight">print</span>(response.text)
          </p>
        </div>
      </div>
      <div className="responses">
        <div className="response" onClick={() => showExample("code1")}>
          <div className="greenCircle"></div>
          <p>HTTP code 200 - Successful Response</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code1">
          <FaTimes className="close" onClick={() => hideExample("code1")} />
          <p>&#123;</p>
          <p className="tab">"dga_detected": true,</p>
          <p className="tab">"data_exfiltration_detected": false</p>
          <p>&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code2")}>
          <div className="redCircle"></div>
          <p>HTTP code 401 - Not authenticated</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code2">
          <FaTimes className="close" onClick={() => hideExample("code2")} />
          <p>&#123;"detail": "Not Authenticated"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code3")}>
          <div className="redCircle"></div>
          <p>
            HTTP code 403 - User has not permissions to access the requested
            resource
          </p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code3">
          <FaTimes className="close" onClick={() => hideExample("code3")} />
          <p>
            &#123;"detail": "User has not permissions to access the requested
            resource"&#125;
          </p>
        </div>

        <div className="response" onClick={() => showExample("code4")}>
          <div className="redCircle"></div>
          <p>HTTP code 404 - Resource not found</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code4">
          <FaTimes className="close" onClick={() => hideExample("code4")} />
          <p>&#123;"detail": "Resource not found"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code5")}>
          <div className="redCircle"></div>
          <p>HTTP code 422 - Validation error</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code5">
          <FaTimes className="close" onClick={() => hideExample("code5")} />
          <p>&#123;"detail": "Validation error"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code6")}>
          <div className="redCircle"></div>
          <p>HTTP code 500 - Error processing the request</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code6">
          <FaTimes className="close" onClick={() => hideExample("code6")} />
          <p>&#123;"detail": "Not Found"&#125; </p>
        </div>
      </div>
      <p className="field">Response Fields</p>
      <ul className="variables">
        <p className="subfield">
          The response JSON object contains the following fields:
        </p>
        <li className="italic">
          <span className="bold">dga_detected:</span>it is "true" if the service
          detected DGA, "false" otherwise
        </li>
        <li className="italic">
          <span className="bold">data_exfiltration_detected:</span>it is "true"
          if the service detected data exfiltration, "false" otherwise
        </li>
      </ul>
    </div>
  );
};

export default LookupDNS;
