import { toast } from "react-toastify";
import { FaCopy, FaExpandAlt, FaTimes } from "react-icons/fa";

const CVEs = () => {
  const showExample = (show) => {
    document.getElementById(show).classList.remove("hidden");
  };

  const hideExample = (hide) => {
    document.getElementById(hide).classList.add("hidden");
  };

  const copyExample = () => {
    navigator.clipboard.writeText(
      "import requests\r\nurl = 'https://api.feeds.vederelabs.com/feedservice/api/cves/vlkev'\r\nheaders = {\r\n\t'api-key' : '{your_api_key}'\r\n}\r\nresponse = requests.get(url, headers=headers)\r\nprint(response.text)"
    );
    toast.success("API request has been copied!", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  return (
    <div className="column">
      <div className="desc-section">
        <h2>Observed Exploited CVEs</h2>
        <p>
          This API query call will enable you to get a list of Vedere Labs known exploited vulnerabilities (VL-KEV).  This call will return a STIX Bundle with a list of Vulnerability objects and related Sighting objects.
        </p>
      </div>
      <div className="example">
        <div className="codeHeader">
          <p>API Request (Python)</p>
          <FaCopy className="icon" onClick={() => copyExample()} />
        </div>
        <div className="code">
          <p className="codeLine">
            <span className="highlight">import</span> requests
          </p>
          <p className="codeLine">
            url <span className="highlight">= "</span>
            <span className="quote">
              https://api.feeds.vederelabs.com/feedservice/api/cves/vlkev?minimum_confidence=high
            </span>
            <span className="highlight">"</span>
          </p>
          <p className="codeLine">
            headers <span className="highlight">= </span> &#123;
          </p>
          <p className="codeLine tab">
            <span className="highlight">'api-key' </span>:
            <span className="highlight"> '&#123;your_api_key&#125;'</span>
          </p>
          <p className="codeLine">&#125;</p>
          <p className="codeLine">
            response <span className="highlight">=</span> requests.get(url,
            headers=headers)
          </p>
          <p className="codeLine">
            <span className="secHighlight">print</span>(response.text)
          </p>
        </div>
      </div>
      <div className="responses">
        <div className="response" onClick={() => showExample("code1")}>
          <div className="greenCircle"></div>
          <p>HTTP code 200 - Successful Response</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code1">
          <FaTimes className="close" onClick={() => hideExample("code1")} />
          <p>&#123;</p>
          <p className="tab">"type": "bundle",</p>
          <p className="tab">
            "id": "bundle--4ef653ea-928a-45dd-b87a-343783310bd9",
          </p>
          <p className="tab">"objects": [</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "vulnerability",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "vulnerability--e2938211-3c93-4d0d-b600-fb27b61b16cf",
          </p>
          <p className="tab3">"created": "2023-07-14T09:05:58.875321Z",</p>
          <p className="tab3">"modified": "2023-07-14T09:05:58.875321Z",</p>
          <p className="tab3">"name": "CVE-2013-7471",</p>
          <p className="tab3">
            "description": "An issue was discovered in soap.cgi?service=WANIPConn1 on D-Link DIR-845 before v1.02b03, DIR-600 before v2.17b01, DIR-645 before v1.04b11, DIR-300 rev. B, and DIR-865 devices. There is Command Injection via shell metacharacters in the NewInternalClient, NewExternalPort, or NewInternalPort element of a SOAP POST request.",
          </p>
          <p className="tab3">"labels": [</p>
          <p className="tab4">"is_cisa_kev":false</p>
          <p className="tab3">],</p>
          <p className="tab3">"external_references": [</p>
          <p className="tab4">&#123;</p>
          <p className="tab5">"source_name": "cve",</p>
          <p className="tab5">"url": "https://nvd.nist.gov/vuln/detail/CVE-2013-7471",</p>
          <p className="tab5">"external_id": "CVE-2013-7471"</p>
          <p className="tab4">&#125;</p>
          <p className="tab3">]</p>
          <p className="tab2">&#125;,</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "sighting",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "sighting--fb979be2-b2ed-4623-9595-7971494b2001",
          </p>
          <p className="tab3">"created": "2023-07-14T09:05:58.876475Z",</p>
          <p className="tab3">
            "modified": "2023-07-14T09:05:58.876475Z",
          </p>
          <p className="tab3">"first_seen": "2023-06-17T13:03:49.04Z",</p>
          <p className="tab3">"last_seen": "2023-06-28T03:12:43.426Z",</p>
          <p className="tab3">"count": 5,</p>
          <p className="tab3">"sighting_of_ref": "vulnerability--e2938211-3c93-4d0d-b600-fb27b61b16cf"</p>
          <p className="tab2">&#125;</p>
          <p className="tab">]</p>
          <p>&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code2")}>
          <div className="redCircle"></div>
          <p>HTTP code 401 - Not authenticated</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code2">
          <FaTimes className="close" onClick={() => hideExample("code2")} />
          <p>&#123;"detail": "Not Authenticated"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code3")}>
          <div className="redCircle"></div>
          <p>
            HTTP code 403 - User has not permissions to access the requested
            resource
          </p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code3">
          <FaTimes className="close" onClick={() => hideExample("code3")} />
          <p>
            &#123;"detail": "User has not permissions to access the requested
            resource"&#125;
          </p>
        </div>

        <div className="response" onClick={() => showExample("code4")}>
          <div className="redCircle"></div>
          <p>HTTP code 404 - Resource not found</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code4">
          <FaTimes className="close" onClick={() => hideExample("code4")} />
          <p>&#123;"detail": "Resource not found"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code5")}>
          <div className="redCircle"></div>
          <p>HTTP code 422 - Validation error</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code5">
          <FaTimes className="close" onClick={() => hideExample("code5")} />
          <p>&#123;"detail": "Validation error"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code6")}>
          <div className="redCircle"></div>
          <p>HTTP code 500 - Error processing the request</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code6">
          <FaTimes className="close" onClick={() => hideExample("code6")} />
          <p>&#123;"detail": "Not Found"&#125; </p>
        </div>
      </div>
      <div className="tableOverflow">
        <table className="table">
          <thead>
            <tr className="tableRow">
              <th className="tableHead">Parameter</th>
              <th className="tableHead">Description</th>
              <th className="tableHead">Example</th>
              <th className="tableHead">Default</th>
            </tr>
          </thead>
          <tbody>
            <tr className="tableRow">
              <td className="tableData">start</td>
              <td className="tableData">
                Start of the time period. The format can be either a date in ISO
                format or a relative start (e.g: now-7d)
              </td>
              <td className="tableData">2022-09-31, now-2d, etc. </td>
              <td className="tableData">It returns all CVEs</td>
            </tr>
            <tr className="tableRow">
              <td className="tableData">is_cisa_kev</td>
              <td className="tableData">If it is set to "true" the query returns all the CVEs included in the CISA KEV catalog. If it is set to "false" it only returns the CVEs not included in the CISA KEV catalog. If the filter is missing it returns all the CVEs.</td>
              <td className="tableData">true</td>
              <td className="tableData">It returns all CVEs</td>
            </tr>
            <tr className="tableRow">
              <td className="tableData">minimum_confidence</td>
              <td className="tableData">Returns all the CVEs with a confidence greater or equal to the one selected. Available confidence levels: "low", "medium", or "high"</td>
              <td className="tableData">high</td>
              <td className="tableData">It returns all CVEs</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className="field">Response Fields</p>
      <ul className="variables">
        <p className="subfield">STIX Bundle</p>
        <li className="italic">
          <span className="bold">type:</span>type of the STIX Object, it must be
          "bundle" in this case
        </li>
        <li className="italic">
          <span className="bold">id:</span>the ID of the Bundle
        </li>
        <li className="italic">
          <span className="bold">objects:</span>a list of STIX Objects
        </li>
      </ul>
      <ul className="variables">
        <p className="subfield">STIX Vulnerability Object</p>
        <li className="italic">
          <span className="bold">type:</span>type of the STIX Object. In this
          case, it must be "vulnerability"
        </li>
        <li className="italic">
          <span className="bold">spec_version:</span>the version of the STIX
          specification used to represent this object. The value of this
          property must be 2.1
        </li>
        <li className="italic">
          <span className="bold">id:</span>the ID of the Vulnerability
        </li>
        <li className="italic">
          <span className="bold">created:</span>the time at which the STIX
          Vulnerability Object was originally created
        </li>
        <li className="italic">
          <span className="bold">modified:</span>the time that this particular
          version of the STIX Object was last modified
        </li>
        <li className="italic">
          <span className="bold">name:</span>the name used to identify the
          Vulnerability
        </li>
        <li className="italic">
          <span className="bold">description:</span>a description that provides
          more details and context about the Vulnerability
        </li>
        <li className="italic">
          <span className="bold">confidence:</span>an integer that represents the level of confidence of the KEV. The higher the confidence the more likely the CVE exploitation could be observed. The confidence levels used are the ones defined in <a href="https://docs.oasis-open.org/cti/stix/v2.1/cs01/stix-v2.1-cs01.html#_1v6elyto0uqg" target="_blank" >STIX 2.1</a>
        </li>
        <li className="italic">
          <span className="bold">labels: </span>labels or tags associated with the CVE (e.g., is_cisa_kev:true)
        </li>
        <li className="italic">
          <span className="bold">external_references: </span> references to the CVE on the nvd.nist.gov platform
        </li>
        <li>
          Each Vulnerability is related to a STIX Sighting Object, <span className="bold">which contains further information such as when the vulnerability was first seen, last seen, and how many times. It also contains the attribute x_unique_attacker_ip_counter that specifies the number of unique IP addresses from which the exploitation attempts are coming.</span>
        </li>
      </ul>

    </div>
  );
};

export default CVEs;
