import { useMemo } from 'react';
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    Bar,
    Cell,
    LabelList,
    Legend,
    Tooltip
} from 'recharts';
import { COLORS } from '../../utils/colors';
import Info from '../Cyberdash/Info';

/**
 * Make some changes to the raw data for better appearance.
 * @param {Array} data 
 */
function cleanData(data) {
    return (
        data.map((d) => (
            {
                key: d.key,
                value: d.value,
                shortKey: (d.value < 10) ? '' : d.key
            }
        ))
    );
}

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">
                    {payload[0]['payload']['key']}
                    <br />
                    {payload[0]['payload']['value']}
                </p>
            </div>
        );
    }

    return null;
};

const CustomLegend = ({ payload }) => {
    return (
        <ul className='CustomLegend'>
            {payload.map((entry, index) => (
                <li className='LegendItem' key={`item-${index}`} style={{ color: COLORS[index % COLORS.length], fontWeight: 700 }}>{entry.key}: {entry.value}</li>
            ))}
        </ul>
    )
}

function LargeBarWrapper(props) {
    const { data, title, tooltip } = props.data;
    const cleanedData = useMemo(() => cleanData(data), [data])

    return (
        <div className="BarWrapper">
            <Info description={tooltip} />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={cleanedData}
                >
                    <Bar dataKey="value" barSize={35}>
                        {data.map((datum, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                                fontWeight={700}
                            />
                        ))}
                        <LabelList dataKey="value" position="right" />
                    </Bar>
                    <XAxis
                        dataKey="value"
                        type="number"
                        domain={[0, "auto"]}
                        padding={{ right: 30 }}
                        hide
                    >
                    </XAxis>
                    <YAxis
                        dataKey="shortKey"
                        type="category"
                        hide
                    >
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
                    <Legend dataKey="shortKey" payload={cleanedData} content={<CustomLegend />} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default LargeBarWrapper;