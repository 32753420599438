import { React } from "react"
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store"
import App from "./pages/App.js";
import "./styles/reset.css";
import "./styles/Cyberdash.css";
import "./styles/index.css";
import "./styles/Documentation.css";
import "./styles/ThreatBriefs.css";

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
