import { toast } from "react-toastify";
import { FaCopy, FaExpandAlt, FaTimes } from "react-icons/fa";

const VLCVEs = () => {
    const showExample = (show) => {
        document.getElementById(show).classList.remove("hidden");
    };

    const hideExample = (hide) => {
        document.getElementById(hide).classList.add("hidden");
    };

    const copyExample = () => {
        navigator.clipboard.writeText(
            "import requests\r\nurl = 'https://api.feeds.vederelabs.com/feedservice/api/cves/vloriginal'\r\nheaders = {\r\n\t'api-key' : '{your_api_key}'\r\n}\r\nresponse = requests.get(url, headers=headers)\r\nprint(response.text)"
        );
        toast.success("API request has been copied!", {
            position: toast.POSITION.TOP_LEFT,
        });
    };

    return (
        <div className="column">
            <div className="desc-section">
                <h2>Vedere Labs Original CVEs</h2>
                <p>
                    This API query call will enable you to get the list of CVEs originally discovered by Vedere Labs as well as all the software related to it. This call will return a dictionary that has the Vedere Labs' project names as key, and a list of STIX Bundle Objects as value. Each STIX Bundle contains STIX Vulnerability Objects to represent the CVE, as well as STIX Software Observables to represent the CPE related to it , if they exist.
                </p>
            </div>
            <div className="example">
                <div className="codeHeader">
                    <p>API Request (Python)</p>
                    <FaCopy className="icon" onClick={() => copyExample()} />
                </div>
                <div className="code">
                    <p className="codeLine">
                        <span className="highlight">import</span> requests
                    </p>
                    <p className="codeLine">
                        url <span className="highlight">= "</span>
                        <span className="quote">
                            https://api.feeds.vederelabs.com/feedservice/api/cves/vloriginal
                        </span>
                        <span className="highlight">"</span>
                    </p>
                    <p className="codeLine">
                        headers <span className="highlight">= </span> &#123;
                    </p>
                    <p className="codeLine tab">
                        <span className="highlight">'api-key' </span>:
                        <span className="highlight"> '&#123;your_api_key&#125;'</span>
                    </p>
                    <p className="codeLine">&#125;</p>
                    <p className="codeLine">
                        response <span className="highlight">=</span> requests.get(url,
                        headers=headers)
                    </p>
                    <p className="codeLine">
                        <span className="secHighlight">print</span>(response.text)
                    </p>
                </div>
            </div>
            <div className="responses">
                <div className="response" onClick={() => showExample("code1")}>
                    <div className="greenCircle"></div>
                    <p>HTTP code 200 - Successful Response</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code1">
                    <FaTimes className="close" onClick={() => hideExample("code1")} />
                    <p>&#123;</p>
                    <p className="tab">"type": "bundle",</p>
                    <p className="tab">"id": "bundle--2b07cd3d-b27a-4528-81e4-5279186eb2ff",</p>
                    <p className="tab">"objects": [</p>
                    <p className="tab2">
                        &#123;
                    </p>
                    <p className="tab3">"type": "vulnerability",</p>
                    <p className="tab3"> "spec_version": "2.1",</p>
                    <p className="tab3">"id": "vulnerability--0b8dc03e-c8ae-43c8-a29d-d26db349797b",</p>
                    <p className="tab3">"created": "2024-10-02T00:00:00.000Z",</p>
                    <p className="tab3">
                        "modified": "2024-11-07T22:20:35.073957Z",
                    </p>
                    <p className="tab3">"name": "CVE-2024-41594",</p>
                    <p className="tab3">"description": "The httpd server of Vigor management UI uses a static string for seeding the PRNG of OpenSSL. This may allow attackers to   achieve information disclosure.",</p>
                    <p className="tab3">"labels": [</p>
                    <p className="tab4">
                        "project=Dray:Break"
                    </p>
                    <p className="tab3">],</p>
                    <p className="tab3">"external_references": [</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"source_name": "Forescout Research - Vedere Labs",</p>
                    <p className="tab5">
                        "url": "https://www.draytek.com/about/security-advisory/"
                    </p>
                    <p className="tab4">&#125;</p>
                    <p className="tab3">
                        ],
                    </p>
                    <p className="tab3">"object_marking_refs": [</p>
                    <p className="tab4">"marking-definition--613f2e26-407d-48c7-9eca-b8e91df99dc9"</p>
                    <p className="tab3">],</p>
                    <p className="tab3">"x_CVSSv3.x": 7.6,</p>
                    <p className="tab3">"x_CVSSv3.x_vector": "AV:N/AC:L/PR:N/UI:N/S:U/C:H/I:N/A:N",</p>
                    <p className="tab3">"x_remediation": "•\tUpdate the router to one of the following versions: 4.3.2.8 or 4.4.3.1 (Vigor1000B, Vigor2962, Vigor3910); 4.3.6.1 (Vigor3912); 4.2.7 (Vigor165, Vigor166); 4.4.5.1 (Vigor2135, Vigor2763, Vigor2765, Vigor2766); 4.4.5.3 (Vigor2865, Vigor2866); 4.4.3.2 (Vigor2915)\n•\tIf remote access is enabled on your router, disable it if not needed. Use an access control list and two-factor authentication if possible.\n•\tVerify that no additional remote access profiles (VPN dial-in, teleworker or LAN to LAN) or admin users (for router admin) have been added and that no ACLs have been altered.\n•\tDisable remote access (admin) and SSL VPN. Since the ACL does not apply to SSL VPN connections (Port 443), temporarily disable SSL VPN until the firmware is updated.\n•\tEnable syslog logging to monitor for abnormal events.",</p>
                    <p className="tab3">"x_title": "DrayTek Vigor Routers: Insufficient entropy in PRNG"</p>
                    <p className="tab2">
                        &#125;,
                    </p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "software",</p>
                    <p className="tab3">"spec_version": "2.1",</p>
                    <p className="tab3">"id": "software--e0a3d043-2171-5b02-af2d-b85b105e2ab5",</p>
                    <p className="tab3">
                        "name": "Vigor Routers",
                    </p>
                    <p className="tab3">"cpe": "",</p>
                    <p className="tab3">"vendor": "DrayTek",</p>
                    <p className="tab3">"version": "&lt;= 4.3.2.6."</p>
                    <p className="tab2">
                        &#125;,
                    </p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "relationship",</p>
                    <p className="tab3">"spec_version": "2.1",</p>
                    <p className="tab3">"id": "relationship--0e10b07f-8d21-47dd-82a2-291f91ec883c",</p>
                    <p className="tab3">
                        "created": "2024-11-07T22:20:35.074541Z",
                    </p>
                    <p className="tab3">"modified": "2024-11-07T22:20:35.074541Z",</p>
                    <p className="tab3">
                        "relationship_type": "has",
                    </p>
                    <p className="tab3">"source_ref": "software--e0a3d043-2171-5b02-af2d-b85b105e2ab5",</p>
                    <p className="tab3">"target_ref": "vulnerability--0b8dc03e-c8ae-43c8-a29d-d26db349797b"</p>
                    <p className="tab2">&#125;,</p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "marking-definition",</p>
                    <p className="tab3"> "spec_version": "2.1",</p>
                    <p className="tab3">
                        "id": "marking-definition--613f2e26-407d-48c7-9eca-b8e91df99dc9",
                    </p>
                    <p className="tab3">"created": "2017-01-20T00:00:00.000Z",</p>
                    <p className="tab3">
                        "definition_type": "tlp",
                    </p>
                    <p className="tab3">"name": "TLP:WHITE",</p>
                    <p className="tab3">"definition": &#123;</p>
                    <p className="tab4">"tlp": "white"</p>
                    <p className="tab3">&#125;</p>
                    <p className="tab2">&#125;</p>
                    <p className="tab">]</p>
                    <p>&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code2")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 401 - Not authenticated</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code2">
                    <FaTimes className="close" onClick={() => hideExample("code2")} />
                    <p>&#123;"detail": "Not Authenticated"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code3")}>
                    <div className="redCircle"></div>
                    <p>
                        HTTP code 403 - User has not permissions to access the requested
                        resource
                    </p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code3">
                    <FaTimes className="close" onClick={() => hideExample("code3")} />
                    <p>
                        &#123;"detail": "User has not permissions to access the requested
                        resource"&#125;
                    </p>
                </div>

                <div className="response" onClick={() => showExample("code4")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 404 - Resource not found</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code4">
                    <FaTimes className="close" onClick={() => hideExample("code4")} />
                    <p>&#123;"detail": "Resource not found"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code5")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 422 - Validation error</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code5">
                    <FaTimes className="close" onClick={() => hideExample("code5")} />
                    <p>&#123;"detail": "Validation error"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code6")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 500 - Error processing the request</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code6">
                    <FaTimes className="close" onClick={() => hideExample("code6")} />
                    <p>&#123;"detail": "Not Found"&#125; </p>
                </div>
            </div>
            <p className="field">Response Fields</p>
            <ul className="variables">
                <p className="subfield">STIX Bundle</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object, it must be
                    "bundle" in this case
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the Bundle
                </li>
                <li className="italic">
                    <span className="bold">objects:</span>a list of STIX Objects
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">STIX Vulnerability Object</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object. In this
                    case, it must be "vulnerability"
                </li>
                <li className="italic">
                    <span className="bold">spec_version:</span>the version of the STIX
                    specification used to represent this object. The value of this
                    property must be 2.1
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the Vulnerability
                </li>
                <li className="italic">
                    <span className="bold">created:</span>the time at which the STIX
                    Vulnerability Object was originally created
                </li>
                <li className="italic">
                    <span className="bold">modified:</span>the time that this particular
                    version of the STIX Object was last modified
                </li>
                <li className="italic">
                    <span className="bold">name:</span>the name used to identify the
                    Vulnerability
                </li>
                <li className="italic">
                    <span className="bold">description:</span>a description that provides
                    more details and context about the Vulnerability
                </li>
                <li className="italic">
                    <span className="bold">labels:</span>list of labels, which includes the project name (i.e. "project=Dray:Break")
                </li>
                <li className="italic">
                    <span className="bold">external_references:</span>a list of dictionaries that includes source_name and url. The source name will always be "Forescout Research - Vedere Labs" and the url will be a link to where the report of the vulnerabilities is published
                </li>
                <li className="italic">
                    <span className="bold">x_CVSSv3.x:</span>CVVSv3 base score (custom
                    property)
                </li>
                <li className="italic">
                    <span className="bold">x_CVSSv3.x_vector:</span>vector of base score
                    metrics (custom property)
                </li>
                <li className="italic">
                    <span className="bold">x_remediation(optional)</span>recommended remediation to combat the vulnerability
                </li>
                <li className="italic">
                    <span className="bold">x_title(optional)</span>the title of the vulnerability
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">STIX Software Observable</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object. In this
                    case, it must be "software"
                </li>
                <li className="italic">
                    <span className="bold">spec_version:</span>the version of the STIX
                    specification used to represent this object. The value of this
                    property must be 2.1
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the software
                </li>
                <li className="italic">
                    <span className="bold">name:</span>the name used to identify the
                    software
                </li>
                <li className="italic">
                    <span className="bold">cpe:</span>specifies the Common Platform
                    Enumeration (CPE) entry for the software, if available. The value for
                    this property must be a CPE v2.3 entry from the official NVD CPE
                    Dictionary
                </li>
                <li className="italic">
                    <span className="bold">vendor:</span>specifies the name of the vendor
                    of the software
                </li>
                <li className="italic">
                    <span className="bold">version:</span>specifies the version of the
                    software
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">STIX Relationship Object</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object. In this
                    case, it must be "relationship"
                </li>
                <li className="italic">
                    <span className="bold">spec_version:</span>the version of the STIX
                    specification used to represent this object. The value of this
                    property must be 2.1
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the relationship
                </li>
                <li className="italic">
                    <span className="bold">created:</span>the time at which the relationship was created
                </li>
                <li className="italic">
                    <span className="bold">modified:</span>the time that this particular version of the STIX Object was last modified
                </li>
                <li className="italic">
                    <span className="bold">relationship_type:</span>defines the relationship between the source_ref and the target_ref. In this case it will be "has" and can be read as "source_ref (software) has target_ref (vulnerability)"
                </li>
                <li className="italic">
                    <span className="bold">source_ref:</span>the source reference of the relationship. In this case it will always be a reference to a Software Object
                </li>
                <li className="italic">
                    <span className="bold">target_ref:</span> the target reference of the relationship. In this case it will always be a reference to a Vulnerability Object
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">STIX Marking Definition Object</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object. In this
                    case, it must be "marking definition"
                </li>
                <li className="italic">
                    <span className="bold">spec_version:</span>the version of the STIX
                    specification used to represent this object. The value of this
                    property must be 2.1
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the marking definiton
                </li>
                <li className="italic">
                    <span className="bold">created:</span>the date the Marking Definition Object was created
                </li>
                <li className="italic">
                    <span className="bold">definition_type:</span>the type of the definition object. In this case it will always be "tlp"
                </li>
                <li className="italic">
                    <span className="bold">name:</span>the name of the the Marking Definition Object. In this case, it will be "TLP:WHITE"
                </li>
                <li className="italic">
                    <span className="bold">definition:</span> a dictionary with the key always being "tlp" and the value being "white"
                </li>
            </ul>
        </div>
    );
};

export default VLCVEs;