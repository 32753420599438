import { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import UserContext from "../../context/UserContext";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { signIn, showPassword } = useContext(UserContext);
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();

    const userObj = {
      email: email,
      password: password,
    };

    const res = await signIn(userObj);

    // Reset the form
    setEmail("");
    setPassword("");

    if (res.status === 200) {
      if (res.data.status !== "pending") {
        navigate("/profile");
      }
    } else {
      if (res.data.detail[0].msg) {
        toast.error(res.data.detail[0].msg);
      } else {
        toast.error(res.data.detail);
      }
    }
  };

  return (
    <div className="form-box">
      <p className="header">Sign In</p>
      <form onSubmit={submit} className="form">
        <div className="fieldContainer">
          <input
            className="input"
            type="email"
            name="email"
            placeholder="Email Address*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="fieldContainer">
          <input
            className="input passQry"
            type="password"
            name="password"
            placeholder="Password*"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <FaEye className="eye" onClick={() => showPassword()} />
        </div>
        <button className="submitBtn">Sign In</button>
        <Link to="/forgot" className="reset">
          Forgot your password?
        </Link>
      </form>
    </div>
  );
};

export default SignIn;
