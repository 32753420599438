import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faTwitter,
  faFacebookF,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function Footer({ lastUpdate }) {
  return (
    <footer className="Footer">
      <section className="Footer-forescout">
        <div className="w-full">
          <a href="https://forescout.com" target="_blank">
            <img
              src="icons/forescout-logo-white.svg"
              alt="Forescout company logo"
            />
          </a>
        </div>
        <div className="Footer-social">
          <a href="https://www.linkedin.com/company/forescout-technologies" target="_blank">
            <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
          </a>
          <a href="https://twitter.com/Forescout" target="_blank">
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>
          <a href="https://www.facebook.com/ForescoutTechnologies" target="_blank">
            <FontAwesomeIcon icon={faFacebookF} size="lg" />
          </a>
          <a href="https://www.youtube.com/user/forescout1" target="_blank">
            <FontAwesomeIcon icon={faYoutube} size="lg" />
          </a>
        </div>
      </section>
      <section className="footer-text">
        <div className="Footer-legal">
          <p>© Forescout 2024</p>
          <p>RESEARCH AND ANALYSIS IS PERFORMED ON BEHALF OF CUSTOMERS TO CONTINUOUSLY IMPROVE THE QUALITY OF PRODUCTS AND SERVICES. ANY PERSONALLY IDENTIFIABLE INFORMATION IS ANONYMIZED OR AGGREGATED. Our dashboards aggregate data from a diverse range of sources. These include the Forescout Device Cloud, Forescout Vedere Labs AEE, open-source threat intelligence repositories, and information shared by our partner ecosystem. </p>
        </div>
        <div className="Footer-links">
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.forescout.com/research-labs/"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.forescout.com/vedere-labs-contact/"
              >
                Contact Us
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.forescout.com/privacy-policy/"
              >
                Privacy at Forescout
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.forescout.com/terms-of-use/"
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.forescout.com/company/legal/"
              >
                Legal
              </a>
            </li>
          </ul>
          <p>Data last updated on {lastUpdate}</p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
