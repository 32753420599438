import { useState } from "react"
import "../../styles/tooltip.css"

const Tooltip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        <td
            className="Tooltip-Wrapper"
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {props.children}
            {active && (
                <div className={`Tooltip-Tip ${props.direction || "top"}`}>
                    <p className="Tooltip-Content">{props.content}</p>
                </div>
            )}
        </td>
    );
};

export default Tooltip;
