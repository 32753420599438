import Header from "../components/base/Header"

const UsePolicy = () => {
  return (
    <main>
      <Header text={"Threat Feed Service"} />
      <div className="box">
        <p className="header">Terms of Use</p>
        <div>
          <p className="content">
            These Terms of Use (“Terms”) applies to the Vedere Labs Threat Feed &
            Lookup Service a vailable at feedsregistration.vederelabs.com/
            (“Site”), and all content, services, or information provided by
            subscription to the Site, a nd any other website, created or provided
            by Forescout Technologies, Inc. or Vedere Labs (“Forescout”),
            available for general viewing and use, which references these Terms.
            This Site and the respective c ontent are the property of Forescout
            Technologies, Inc. These Terms apply, but are not limited to, all
            content, services, underlying source files, indexes, databases, and
            other features offered as part of this Site. By subscribing to the
            Site, and clicking on the “Register” button you acknowledge that you
            have read, understood and agree to these Terms. If you subscribe to
            the Site on behalf of any entity, you represent and warrant that you
            have authority to bind such entity to these terms. If you do not
            accept these Terms, do not sign up for the Site.
          </p>
          <p className="content">
            © 2023 by Forescout Technologies, Inc. . All rights reserved.
            Forescout Technologies, Inc. is a Delaware corporation. A list of our
            trademarks and patents is available at
            https://www.forescout.com/company/legal/intellectual-property-patents-trademarks.
            Other brands, products, or service names may be trademarks or service
            marks of their respective owners.
          </p>
          <p className="sec_header">Ownership</p>
          <p className="content">
            The Forescout and Vedere Labs names, logos, and related marks are the
            property of ForescoutTechnologies, Inc, including all associate
            product names and features. This site contains proprietary material of
            Forescout (or its licensors, as applicable) which is protected by
            copyright, trademark, and other U.S. and international property laws
            and conventions. Forescout retains all rights in this Site and the
            materials herein, including, without limitation, all copyright and
            other proprietary rights worldwide in all media.{" "}
          </p>
          <p className="sec_header">Terms of Use</p>
          <p className="content">
            Foresc out reserves the right, at its sole discretion, to change,
            modify, add, or remove portions of theseTerms, at any time. Your
            continued use of t he Site will mean that you accept and agree to
            comply with the then current Terms, and as such, Forescout grants you
            a personal, non-commercial, right to use the Site. All specifications,
            features, a nd content herein may change without notice and may
            contain technical inaccuracies or typographical errors.{" "}
          </p>
          <p className="content">
            You may not use this Site or any materials herein except as expressly
            permitted under these Terms. You will not (i) modify, publish,
            participate in the sale of, reproduce, create derivative works from,
            distribute, perform, display, or in any way exploit, any of the
            content, software, material of the Site in whole or in part; (ii)
            reverse engineer or otherwise attempt to discover any source code or
            underlying technical information of this Site; (iii) use or otherwise
            export or re-export this Site, or any portion thereof, in violation of
            the laws and regulations of the U.S. or any other country; or (iv) use
            the content on this Site for any commercial useor in any manner that
            is commercially prejudicial or competitive with Forescout without the
            prior written consent of Forescout.{" "}
          </p>
          <p className="content">
            Although the information provided to you on this Site is obtained or
            compiled from sources we believe to be reliable, errors or omissions
            may occur. The information on this Site is provided “AS IS”.
            FORESCOUTCANNOT AND DOES NOT MAKE ANY WARRANTIES, REPRESENTATIONS, OR
            UNDERTAKINGS, EITHER EXPRESS OR IMPLIED, ABOUT THE ACCURACY, VALIDITY,
            TIMELINESS, OR COMPLETENESS OF ANY INFORMATION OR DATA MADE AVAILABLE
            IN THE SITE’S MATERIALS, INCLUDING BUT NOT WITHSTANDING THAT ANY FILES
            OR OTHER DATA OBTAINED WITHIN AND FROM THE SITE OR CONTENT WILL BE
            FREE OF VIRUSES OR MALICIOUS FEATURES, AND EXPRESSLY DISCLAIMS ANY
            WARRANTIES OFMERCHANTABILITY, ACCURACY OR FITNESS FOR A PARTICULAR
            PURPOSE.{" "}
          </p>
          <p className="content">
            FORESCOUT WILL NOT BE LIABLE TO ANY PARTY FOR ANY DAMAGE CAUSED IN ANY
            PART BY YOUR USE OF THE SITE, YOUR RELIANCE ON THE INFORMATION
            CONTAINED IN THE SITE OR FOR ANY: DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL OR OTHER CONSEQUENTIAL DAMAGES CAUSED BY THE USE OF THIS
            SITE, INCLUDING WITHOUT LIMITATION ANY LOST PROFITS, INTERRUPTION TO
            BUSINESS, OR LOSS OF PROGRAMS OR OTHER DATA ON YOUR INFORMATION
            HANDLING SYSTEM, EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE
            SITE AND ANY LINKED WEBSITES. YOUR SOLE REMEDY AGAINST FORESCOUT FOR
            DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO STOP USING THE SITE
            OR ANY SUCH CONTENT. Some jurisdictions do not allow the exclusion of
            implied warranties; the above shall not apply in such jurisdictions.
          </p>
          <p className="sec_header">
            Submitted Recommendations, Suggestions and Feedback
          </p>
          <p className="content">
            All submitted recommendations, suggestions and feedback provided by
            you to Forescout in regards to the company, products, services and
            this Site will automatically become the property of Forescout –
            without any compensation to you.
          </p>
          <p className="sec_header">Notice to U.S. Government End Users</p>
          <p className="content">
            Forescout Hardware, Software and Documentation are “Commercial Items,”
            as that term is defined at 48 C.F.R. §2.101, consisting of “Commercial
            Computer Software” and “Commercial Computer Software Documentation,”
            as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as
            applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §
            §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer
            Software and Commercial Computer Software Documentation are being
            licensed to U.S. Government end users (a) only as Commercial Items and
            (b) with only those rights as are granted to all other end users
            pursuant to the terms and conditions herein. Unpublished-rights are
            reserved under the copyright laws of the United States.
          </p>
          <p className="sec_header">Security</p>
          <p className="content">
            The username and password assigned to access the Site are confidential
            and unique to you, and you must not disclose such details to any third
            party. All information provided by you during the registration process
            must be true and accurate and will be relied upon for the provision of
            access to the Site. You are solely responsible for all use of and for
            protecting the confidentiality of your username and password. You are
            responsible for all activities that occur under your registration. Any
            breach of security of a username and password must be notified to us
            immediately. We have the right to disable your username and/or
            password at any time if in our opinion you have failed to comply with
            any of the provisions of these Terms.{" "}
          </p>
          <p className="sec_header">Priacy Policy</p>
          <p className="content">
            Personal data submitted by you in the registration process and certain
            other information about you is subject to our Privacy Policy. For more
            information, please see our Privacy Policy at{" "}
            <a href="https://www.forescout.com/privacy-policy/">
              https://www.forescout.com/privacy-policy/.
            </a>{" "}
          </p>
          <p className="sec_header">Unsubscribe</p>
          <p className="content">
            You can unsubscribe from the Site at any time.
          </p>
          <p className="sec_header">Contact Information</p>
          <p className="content">
            Please direct all questions or concerns to:{" "}
            <a href="emailto: info@forescout.com">info@forescout.com</a>
          </p>
          <p className="content">Forescout Technologies, Inc.</p>
          <p className="content">2400 Dallas Pkwy, Suite 230</p>
          <p className="content">Plano, TX 75093</p>
          <p className="sec_header">Governing laws</p>
          <p className="content">
            The laws applicable to the interpretation of these Terms of Use shall
            be the laws of the State of Delaware without reference to its conflict
            of law provisions. If any provision of these Terms and Conditions
            shall be unlawful, void, or for any reason unenforceable, then that
            provision shall be deemed severable from this agreement and shall not
            affect the validity and enforceability of any remaining provisions.{" "}
          </p>
          <p className="sec_header">Last Updated: July 11th, 2023</p>
        </div>
      </div>
    </main>
  );
};

export default UsePolicy;