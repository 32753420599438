import { FaSortUp, FaSortDown } from 'react-icons/fa'

const VulnTableRow = ({ data, showExpandedView, expanded }) => {
    return (
        <div className='vuln_table_row' onClick={() => showExpandedView(data.cve)} id={`${data.cve}_row`}>
            <div className="date_found">
                {expanded.isExpanded && expanded.cve == data.cve ? <FaSortUp /> : <FaSortDown />}
                <p className='vuln_table_body'>{data.date}</p>
            </div>
            <p className='vuln_table_body'>{data.cve}</p>
            <p className='vuln_table_body'>{data.vendor}</p>
            <p className="vuln_table_body">{data.cvss_score}</p>
            <p className='vuln_table_body'>{data.bundle === "Sierra Wireless" ? "Sierra:21" : data.bundle}</p>
        </div>
    )
}

export default VulnTableRow