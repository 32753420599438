const LATEST_FEED = "latest_feed.json";
const THREAT_BRIEFS = "threat_briefs.json";
const THREAT_DASHBOARD = "threat_dashboard_export.json";
const THREAT_INTEL = "threat_intel.json";

const getLatestFeed = async () => {
    const data = fetch(LATEST_FEED)
        .then(res => res.json())

    return await data
}

const getThreatBriefs = async () => {
    const data = fetch(THREAT_BRIEFS)
        .then(res => res.json())

    return await data
}

const getThreatDashboard = async () => {
    const data = fetch(THREAT_DASHBOARD)
        .then(res => res.json())

    return await data
}

const getThreatIntel = async () => {
    const data = fetch(THREAT_INTEL)
        .then(res => res.json())

    return await data
}

const dataService = {
    getLatestFeed,
    getThreatBriefs,
    getThreatDashboard,
    getThreatIntel,
}

export default dataService