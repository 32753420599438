import { toast } from "react-toastify";
import { FaCopy, FaExpandAlt, FaTimes } from "react-icons/fa";

const IndicatorRelationships = () => {
  const showExample = (show) => {
    document.getElementById(show).classList.remove("hidden");
  };

  const hideExample = (hide) => {
    document.getElementById(hide).classList.add("hidden");
  };

  const copyExample = () => {
    navigator.clipboard.writeText(
      "import requests\r\nurl = 'https://api.feeds.vederelabs.com/feedservice/api/indicators/relationships'\r\nheaders = {\r\n\t'api-key' : '{your_api_key}'\r\n}\r\nresponse = requests.get(url, headers=headers)\r\nprint(response.text)"
    );
    toast.success("API request has been copied!", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  return (
    <div className="column">
      <div className="desc-section">
        <h2>Indicator Relationships</h2>
        <p>
          This API query call will enable you to get all the relationships among
          different IoCs and the related TTPs used by the attacker. The format of
          this query is a list of STIX Bundle Objects containing STIX
          Relationships, Attack-Patterns, and Observables. A STIX Bundle is a
          collection of arbitrary STIX Objects grouped in a single container. Each
          Bundle refers to a specific source IP address.
        </p>
      </div>
      <div className="example">
        <div className="codeHeader">
          <p>API Request (Python)</p>
          <FaCopy className="icon" onClick={() => copyExample()} />
        </div>
        <div className="code">
          <p className="codeLine">
            <span className="highlight">import</span> requests
          </p>
          <p className="codeLine">
            url <span className="highlight">= "</span>
            <span className="quote">
              https://api.feeds.vederelabs.com/feedservice/api/indicators/relationships?start=now-7d&end=now
            </span>
            <span className="highlight">"</span>
          </p>
          <p className="codeLine">
            headers <span className="highlight">= </span> &#123;
          </p>
          <p className="codeLine tab">
            <span className="highlight">'api-key' </span>:
            <span className="highlight"> '&#123;your_api_key&#125;'</span>
          </p>
          <p className="codeLine">&#125;</p>
          <p className="codeLine">
            response <span className="highlight">=</span> requests.get(url,
            headers=headers)
          </p>
          <p className="codeLine">
            <span className="secHighlight">print</span>(response.text)
          </p>
        </div>
      </div>
      <div className="responses">
        <div className="response" onClick={() => showExample("code1")}>
          <div className="greenCircle"></div>
          <p>HTTP code 200 - Successful Response</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code1">
          <FaTimes className="close" onClick={() => hideExample("code1")} />
          <p>[&#123;</p>
          <p className="tab">"type": "bundle",</p>
          <p className="tab">
            "id": "bundle--570a244e-f6f4-4aa2-83e1-1972b1b94a9d",
          </p>
          <p className="tab">"objects": [</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "relationship",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "relationship--eaef231a-484d-4f1d-af5c-7efa33326268",
          </p>
          <p className="tab3">"created": "2022-07-21T13:23:35.143200+00:00",</p>
          <p className="tab3">
            "modified": "2022-07-21T13:23:35.143200+00:00",
          </p>
          <p className="tab3">
            "relationship-type": "http_post_noroot_data_headers",
          </p>
          <p className="tab3">
            "source_ref": "ipv4-addr--320b7966-4782-5b76-b1dc-0c6ddf3d4b7b",
          </p>
          <p className="tab3">
            "target_ref": "url--08bc4ba7-69a6-5e20-93ef-21491194f2ae",
          </p>
          <p className="tab3">"x_dest_port": 80,</p>
          <p className="tab3">"x_14_protocol": "TCP",</p>
          <p className="tab3">"x_17_protocol": "HTTP",</p>
          <p className="tab3">"x_related_to_technique": "T1190",</p>
          <p className="tab3">"x_timestamp": "2022-12-03T12:21:25.171Z"</p>
          <p className="tab2">&#125;</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "relationship"</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "relationship--50030ce5-2f62-4090-bdcb-ca9443794241",
          </p>
          <p className="tab3">"created": "2022-12-14T15:58:58.405584Z",</p>
          <p className="tab3">"modified": "2022-12-14T15:58:58.405584Z",</p>
          <p className="tab3">"relationship_type": "indicates",</p>
          <p className="tab3">
            "source_ref": "url--08bc4ba7-69a6-5e20-93ef-21491194f2ae",
          </p>
          <p className="tab3">
            "target_ref": "attack-pattern--06db00a3-3b31-4c50-a31d-617831d14d63"
          </p>
          <p className="tab2">&#125;,</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "relationship",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "relationship--8eb52301-eac6-4047-a07a-e881ab2b8c81",
          </p>
          <p className="tab3">"created": "2022-12-14T15:58:58.405829Z",</p>
          <p className="tab3">"modified": "2022-12-14T15:58:58.405829Z",</p>
          <p className="tab3">
            "relationship-type": "http_post_noroot_data_headers",
          </p>
          <p className="tab3">
            "source_ref": "ipv4-addr--320b7966-4782-5b76-b1dc-0c6ddf3d4b7b",
          </p>
          <p className="tab3">
            "target_ref": "url--08bc4ba7-69a6-5e20-93ef-21491194f2ae",
          </p>
          <p className="tab3">"x_dest_port": 80,</p>
          <p className="tab3">"x_14_protocol": "TCP",</p>
          <p className="tab3">"x_17_protocol": "HTTP",</p>
          <p className="tab3">"x_related_to_technique": "T1190",</p>
          <p className="tab3">"x_timestamp": "2022-12-03T12:21:25.171Z"</p>
          <p className="tab2">&#125;</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "relationship"</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "relationship--f7cea3f1-0a16-4be7-8c23-3a9abd78e7b5",
          </p>
          <p className="tab3">"created": "2022-12-14T15:58:58.406105Z",</p>
          <p className="tab3">"modified": "2022-12-14T15:58:58.406105Z",</p>
          <p className="tab3">"relationship_type": "indicates",</p>
          <p className="tab3">
            "source_ref": "ipv4-addr--320b7966-4782-5b76-b1dc-0c6ddf3d4b7b",
          </p>
          <p className="tab3">
            "target_ref": "attack-pattern--06db00a3-3b31-4c50-a31d-617831d14d63"
          </p>
          <p className="tab2">&#125;,</p>
          <p className="tab2">&#123;</p>
          <p className="tab3">"type": "attack-pattern",</p>
          <p className="tab3">"spec_version": "2.1",</p>
          <p className="tab3">
            "id": "attack-pattern--06db00a3-3b31-4c50-a31d-617831d14d63",
          </p>
          <p className="tab3">"created": "2022-12-14T15:58:58.396754Z",</p>
          <p className="tab3">"modified": "2022-12-14T15:58:58.396754Z",</p>
          <p className="tab3">"name": "Exploit Public-Facing Application",</p>
          <p className="tab3">
            "description": "Adversaries may attempt to take advantage of a
            weakness in an Internet-facing computer or program using software,
            data, or commands in order to cause unintended or unanticipated
            behavior. The weakness in the system can be a bug, a glitch, or a
            design vulnerability. These applications are often websites, but can
            include databases (like SQL), standard services (like SMB or SSH),
            network device administration and management protocols (like SNMP
            and Smart Install), and any other applications with Internet
            accessible open sockets, such as web servers and related
            services.(Citation: NVD CVE-2016-6662)(Citation: CIS Multiple SMB
            Vulnerabilities)(Citation: US-CERT TA18-106A Network Infrastructure
            Devices 2018)(Citation: Cisco Blog Legacy Device Attacks)(Citation:
            NVD CVE-2014-7169) Depending on the flaw being exploited this may
            include [Exploitation for Defense
            Evasion](https://attack.mitre.org/techniques/T1211). \n\nIf an
            application is hosted on cloud-based infrastructure and/or is
            containerized, then exploiting it may lead to compromise of the
            underlying instance or container. This can allow an adversary a path
            to access the cloud or container APIs, exploit container host access
            via [Escape to Host](https://attack.mitre.org/techniques/T1611), or
            take advantage of weak identity and access management
            policies.\n\nFor websites and databases, the OWASP top 10 and CWE
            top 25 highlight the most common web-based
            vulnerabilities.(Citation: OWASP Top 10)(Citation: CWE top 25)",
          </p>
          <p className="tab3">"kill_chain_phases": [</p>
          <p className="tab4">&#123;</p>
          <p className="tab5">"kill_chain_name": "mitre-attack"</p>
          <p className="tab5">"phase_name": "initial_access"</p>
          <p className="tab4">&#125;</p>
          <p className="tab3">],</p>
          <p className="tab3">"external_references": [</p>
          <p className="tab4">&#123;</p>
          <p className="tab5">"source_name": "mitre-attack",</p>
          <p className="tab5">
            "url": "https://attack.mitre.org/techniques/T1190",
          </p>
          <p className="tab5">"external_id": "T1190",</p>
          <p className="tab 4">&#125;</p>
          <p className="tab3">],</p>
          <p className="tab3">"x_mitre_data_sources": [</p>
          <p className="tab4">"Network Traffic: Network Traffic Content",</p>
          <p className="tab4">"Application Log: Application Log Content",</p>
          <p className="tab3">],</p>
          <p className="tab3">
            "x_mitre_detection": "Monitor application logs for abnormal behavior
            that may indicate attempted or successful exploitation. Use deep
            packet inspection to look for artifacts of common exploit traffic,
            such as SQL injection. Web Application Firewalls may detect improper
            inputs attempting exploitation.",
          </p>
          <p className="tab3">"x_mitre_platforms": [</p>
          <p className="tab4">"Windows",</p>
          <p className="tab4">"IaaS",</p>
          <p className="tab4">"Network",</p>
          <p className="tab4">"Linux",</p>
          <p className="tab4">"macOS",</p>
          <p className="tab4">"Containers"</p>
          <p className="tab3">],</p>
          <p className="tab3">"x_mitre_version": "2.3"</p>
          <p className="tab2">&#125;</p>
          <p className="tab">],</p>
          <p className="tab">
            "x_created_by_ref":
            "ipv4-addr--320b7966-4782-5b76-b1dc-0c6ddf3d4b7b",
          </p>
          <p className="tab">
            "description": "Sent a suspicious HTTP POST request",
          </p>
          <p className="tab">"@timestamp": "2022-12-14T15:58:59.458041"</p>
          <p>&#125;</p>
        </div>
        <div className="response" onClick={() => showExample("code2")}>
          <div className="redCircle"></div>
          <p>HTTP code 401 - Not authenticated</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code2">
          <FaTimes className="close" onClick={() => hideExample("code2")} />
          <p>&#123;"detail": "Not Authenticated"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code3")}>
          <div className="redCircle"></div>
          <p>
            HTTP code 403 - User has not permissions to access the requested
            resource
          </p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code3">
          <FaTimes className="close" onClick={() => hideExample("code3")} />
          <p>
            &#123;"detail": "User has not permissions to access the requested
            resource"&#125;
          </p>
        </div>

        <div className="response" onClick={() => showExample("code4")}>
          <div className="redCircle"></div>
          <p>HTTP code 404 - Not found</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code4">
          <FaTimes className="close" onClick={() => hideExample("code4")} />
          <p>&#123;"detail": "No valid indicators"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code5")}>
          <div className="redCircle"></div>
          <p>HTTP code 422 - Validation error</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code5">
          <FaTimes className="close" onClick={() => hideExample("code5")} />
          <p>&#123;"detail": "Validation error"&#125;</p>
        </div>

        <div className="response" onClick={() => showExample("code6")}>
          <div className="redCircle"></div>
          <p>HTTP code 500 - Error processing the request</p>
          <FaExpandAlt className="expand" />
        </div>
        <div className="code hidden" id="code6">
          <FaTimes className="close" onClick={() => hideExample("code6")} />
          <p>&#123;"detail": "Internal error"&#125; </p>
        </div>
      </div>
      <div className="tableOverflow">
        <table className="table">
          <tr className="tableRow">
            <th className="tableHead">Parameter</th>
            <th className="tableHead">Description</th>
            <th className="tableHead">Example</th>
            <th className="tableHead">Default</th>
          </tr>
          <tr className="tableRow">
            <td className="tableData">Start</td>
            <td className="tableData">
              Start of the time period. The format can be either a date in ISO
              format or a relative start (e.g: now-7d)
            </td>
            <td className="tableData">2022-09-31, now-2d, etc. </td>
            <td className="tableData">Now-30d</td>
          </tr>
          <tr className="tableRow">
            <td className="tableData">End</td>
            <td className="tableData">
              End of the time period. The format can be either a date in ISO
              format or a relative send (e.g: now)
            </td>
            <td className="tableData">2022-10-31, now, etc.</td>
            <td className="tableData">Now</td>
          </tr>
        </table>
      </div>
      <p className="fieldname">Response Fields</p>
      <ul className="variables">
        <p className="subfield">STIX Bundle</p>
        <li className="italic">
          <span className="bold">type:</span>type of the STIX Object, it must be
          "bundle" in this class
        </li>
        <li className="italic">
          <span className="bold">id:</span>the ID of the Bundle
        </li>
        <li className="italic">
          <span className="bold">objects:</span>a list of STIX Objects
        </li>
      </ul>
      <ul className="variables">
        <p className="subfield">STIX Indicator Object</p>
        <li className="italic">
          <span className="bold">type:</span>type of the STIX Object. In this
          case, it must be "bundle"
        </li>
        <li className="italic">
          <span className="bold">id:</span>the ID of the Bundle
        </li>
        <li className="italic">
          <span className="bold">x_created_by_ref:</span>ID of the Observable
          object with the source IP
        </li>
        <li className="italic">
          <span className="bold">x_description:</span>description of the attack
        </li>
        <li className="italic">
          <span className="bold">modified:</span>the time that this particular
          version of the STIX Object was last modified
        </li>
        <li className="italic">
          <span className="bold">objects:</span> a list of STIX Objects
          (Relationships, Observables, and Attack-Patterns)
        </li>
      </ul>
    </div>
  );
};

export default IndicatorRelationships;
