import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaInfo } from "react-icons/fa";
import { toast } from "react-toastify";
import UserContext from "../context/UserContext";
import Explanations from "../components/ThreatFeeds/Explanations";
import PasswordReq from "../components/base/PasswordReq";

const Reset = () => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(false);

  const { resetPassword, showPassword } = useContext(UserContext);
  const navigate = useNavigate();
  // onSubmit calls the function to reset user's password
  // the passwords must match as well as meet the strength requirements
  // we get the token for this api call from the url
  const onSubmit = async (e) => {
    e.preventDefault();

    if (passwordMatch && passwordStrength) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");

      const userObj = {
        password: password,
        token: token,
      };

      const res = await resetPassword(userObj);

      if (res.status === 200) {
        toast.success("Your password has been reset", {
          position: toast.POSITION.TOP_LEFT,
          onOpen: () => navigate("/signin"),
        });
      } else {
        if (res.response.data.detail[0].msg) {
          toast.error(res.response.data.detail[0].msg);
        } else {
          toast.error(res.data.detail);
        }
      }
    } else {
      setPassword2("");
      toast.error("Passwords must match and meet the required strength.");
    }
  };

  // Check that the password meets password requirements
  const checkStrength = (e) => {
    setPassword(e.target.value);

    if (e.target.value === password2) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }

    if (e.target.value.length > 7) {
      var pattern = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
      );

      if (!pattern.test(e.target.value)) {
        setPasswordStrength(false);
      } else {
        setPasswordStrength(true);
      }
    } else {
      setPasswordStrength(false);
    }
  };

  const showPasswordRequirements = () => {
    const el = document.getElementById("passReq");

    el.classList.toggle("hidden")
  }

  // Check that both passwords match
  const checkPasswords = (e) => {
    setPassword2(e.target.value);

    if (password === e.target.value) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  };

  return (
    <main>
      <section className="eindhoven-background-image">
        <div className="boxes">
          <Explanations />
          <div className="form-box">
            <p className="header">Password Reset</p>
            <form onSubmit={onSubmit}>
              <PasswordReq password={password} />
              <div className="fieldContainer">
                <input
                  className="input passQry"
                  type="password"
                  placeholder="Password*"
                  name="password"
                  value={password}
                  onChange={(e) => checkStrength(e)}
                  required
                />
                <div className="info-icon-container" onClick={() => showPasswordRequirements()}>
                  <FaInfo className="info-icon" />
                </div>
              </div>
              <div className="fieldContainer">
                <input
                  className="input passQry passInput"
                  placeholder="Confirm Password*"
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={(e) => checkPasswords(e)}
                  required
                />
                <FaEye className="eye" onClick={() => showPassword()} />
              </div>
              <button className="submitBtn" type="submit">
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Reset;
